import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import intlTelInput from 'intl-tel-input';
import { InfoPopUpComponent } from '../../pop-up/info-pop-up/info-pop-up.component';
import { InquirysService } from '@app/services/inquirys/inquirys.service';

@Component({
  selector: 'app-need-more-info-form',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoModule, InfoPopUpComponent],
  templateUrl: './need-more-info-form.component.html',
  styleUrl: './need-more-info-form.component.scss'
})
export class NeedMoreInfoFormComponent {
  inquiryService = inject(InquirysService);
  translateService = inject(TranslocoService);
  
  @ViewChild('phoneInput') phoneInput!: ElementRef;

  @Input() packageId: string;

  showPopUp: boolean = false;
  formSubmitted: boolean = false;
  
  popUpMessage: string = '';
  currentLang: string;
  countryData: string;
  
  contactForm = this.formBluider.group({
    name: ['',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(/^[a-zA-Z\s]*$/),
        Validators.pattern(/^[\p{L}\s]*$/u),
        Validators.maxLength(30),
      ]),
    ],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required],
    dialCode: ['']
  })

  constructor(private formBluider: FormBuilder) {
    this.currentLang = this.translateService.getActiveLang()
  }

  async onSubmitContactForm() {
    
    this.formSubmitted = true;

    if (this.contactForm.valid) {

      const jsonData = {
        email: this.contactForm.get('email').value,
        name: this.contactForm.get('name').value,
        phone: this.contactForm.get('phone').value,
        languageISO: this.currentLang,
        packageId: this.packageId,
      };
              
      try {
        const res = await this.inquiryService.contactUsInquiry(jsonData)

        if (res.isSuccessful && res.result) {
          this.contactForm.reset();
          this.formSubmitted = false;
          this.popUpMessage = 'contact'
          this.showPopUp = true;
        } else {
          this.popUpMessage = 'server-error'
          this.showPopUp = true;
        }
        
      } catch (error) {
        console.log(error)
        this.popUpMessage = 'server-error'
        this.showPopUp = true;
      }

    }
  }

  ngAfterViewInit() {
    const inputElement = this.phoneInput.nativeElement;
    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        initialCountry: 'US',
        separateDialCode: true,
        formatOnDisplay: true,
        strictMode: true,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js",
      }); 
      
      inputElement.addEventListener('input', () => {
        const phoneNumber = iti.getNumber();
        const dialCode = iti.getSelectedCountryData().dialCode;
        const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();
        this.countryData = iti.getSelectedCountryData().name

        this.contactForm.get('phone').setValue(nationalNumber);
        this.contactForm.get('dialCode').setValue(dialCode);
        this.contactForm.get('phone').updateValueAndValidity();
      });
    }
  }

  phoneNumberValidator(control: FormControl): Promise<{ [key: string]: boolean } | null> {
    return new Promise(resolve => {
      const cleanedNumber = control.value;
  
      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement; 
        const iti = intlTelInput.getInstance(input);
  
        if (iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }

  onClosePopUp() {
    this.showPopUp = false;
  }
}
