import {
  Component,
  ElementRef,
  HostListener,
  inject,
  ViewChild,
} from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';

import { IMyVNumbers } from '@app/services/models/my-vnumbers.model';
import { PhoneFormatPipe } from '@app/pipes/phone-format.pipe';
import { MyVnumbersService } from '@app/services/my-vnumbers/my-vnumbers.service';
import { VnumberDetailsComponent } from '../vnumber-details/vnumber-details.component';
import { GlobalNumbersComponent } from '../global-numbers/global-numbers.component';
import { CallHistoryComponent } from '../call-history/call-history.component';

@Component({
  selector: 'app-my-vnumbers',
  standalone: true,
  imports: [
    FlagsPipe,
    TranslocoModule,
    GlobalNumbersComponent,
    CallHistoryComponent,
    PhoneFormatPipe,
    VnumberDetailsComponent
  ],
  templateUrl: './my-vnumbers.component.html',
  styleUrl: './my-vnumbers.component.scss',
})
export class MyVnumbersComponent {
  @ViewChild('popover') popoverElement: ElementRef;
  openPopoverIndex: number | null = null;
  translocoService = inject(TranslocoService);
  numbers: IMyVNumbers[];
  selectedNumber: IMyVNumbers | null = null;

  constructor(private el: ElementRef, private myVnumberService: MyVnumbersService) {}
  
  ngOnInit(): void {
    this.getMyVNumbers();
  }
  
  toggleDetails(number: IMyVNumbers): void {
    this.selectedNumber = this.selectedNumber === number ? null : number;
  }

  saveChanges() {
    console.log('Changes saved');
  }

  getStatusText(active: boolean): string {
    const key = active
      ? 'profile-vn.my-vnumbers.active-check'
      : 'profile-vn.my-vnumbers.deactivate-check';
    return this.translocoService.translate(key);
  }

  configureForwarding(index: number) {
    if (this.openPopoverIndex === index) {
      this.openPopoverIndex = null; // If the popover is already open, we close it.
    } else {
      this.openPopoverIndex = index; // Otherwise, we open this popover and close any other popover.
    }
  }

  sendEmail(index: number) {
    console.log(`Enviando email para ${this.numbers[index].didNumber}`);
    this.openPopoverIndex = null;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (
      this.openPopoverIndex !== null &&
      !this.el.nativeElement.contains(event.target)
    ) {
      setTimeout(() => {
        this.openPopoverIndex = null;
      });
    }
  }

  async getMyVNumbers() {
    try {
      const res = await this.myVnumberService.getMyVNumbers<IMyVNumbers[]>()

      if (res.isSuccessful) {
        this.numbers = res.result
      }
    } catch (error) {
      console.log(error)
    }
  }
}
