import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFlags',
  standalone: true,
})
export class FlagsPipe implements PipeTransform {

  transform(value: string): string {

    if (value.toLowerCase() === 'us') {
      return 'assets/images/flags/united-states-flag.svg';
    }

    if (value.toLowerCase() === 'br') {
      return 'assets/images/flags/brazil-flag.svg';
    }

    if (value.toLowerCase() === 'il') {
      return 'assets/images/flags/israel-flag.svg';
    }

    if (value) {
      return 'assets/images/flags/' + value.toLowerCase().replaceAll(" ", "-") + '-flag.svg';
    } 
    
    return 'assets/images/flags/world.svg'
  }

}
