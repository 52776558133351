import { Component, effect, ElementRef, HostListener, inject } from '@angular/core';
import { NavigationEnd, RouterModule, Router } from '@angular/router';
import { AccountInfoService } from '@app/services/account-info/account-info.service';
import { TranslocoModule } from '@jsverse/transloco';
import { RandomColorPipe } from 'src/app/pipes/random-color.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IAccountInformation, UserInformation } from 'src/app/services/models/user-information.model';

@Component({
  selector: 'app-profile-dropdown',
  standalone: true,
  imports: [TranslocoModule, RouterModule, RandomColorPipe],
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent {
  dropdownVisible = false;
  authService = inject(AuthService);
  accountInfoService = inject(AccountInfoService);

  userData: IAccountInformation;

  constructor(private el: ElementRef, private router: Router,) {
    this.router.events.subscribe(value => {
      if(value instanceof NavigationEnd)    
      if (this.dropdownVisible) {
        this.dropdownVisible = false;
      }
      });

      effect(() => {
        const signalValue = this.accountInfoService.accountInfoUpdatedSignal();
  
        if (signalValue === true) {
          console.log('info updated');
          this.getAccountInfo();
        }

        // Reset the signal to false after executing the action
          this.accountInfoService.onInfoUpdated(false);

      }, {allowSignalWrites: true});
  }

  ngOnInit(): void {
    this.getAccountInfo()
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {

    if (this.dropdownVisible) {
      if (!this.el.nativeElement.contains(event.target)) {
        this.dropdownVisible = false;
      }
    }
  }

  logout() {
    this.authService.logout()
  }

  async getAccountInfo() {
    try {
      const res =
        await this.accountInfoService.getAccountInfo<IAccountInformation>();

      if (res.isSuccessful && res.result) {
        this.userData = res.result;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
