import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-login-social-media',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './login-social-media.component.html',
  styleUrl: './login-social-media.component.scss'
})
export class LoginSocialMediaComponent {

}
