@if(didData) {
  <main class="px-5">
    <section class="flex justify-center">
      <div class="flex flex-col w-fit py-16">
        <img src="assets/images/icons/plans/5vnumber.svg" alt="" class="w-20  ml-auto">
        <h2 class="text-center px-12 py-4 font-semibold" transloco="payment.title"></h2>
        <img src="assets/images/icons/FAQ_1vnumber.svg" alt="" class="w-40 ">
      </div>
    </section>

    <section class="flex flex-col lg:flex-row gap-10 justify-center">
      <article class="flex flex-col gap-10 w-full max-w-[640px]">

        <div class="flex gap-4 items-center shadow-full rounded-3xl p-4 lg:p-8 w-full">
          <div class="relative w-1/4">
            <img [src]="didData?.countryName | appSquares" alt="country sqaure" class="w-full rounded-2xl">
            <img [src]="didData?.countryName | appFlags" class="w-12 absolute -right-5 top-12">
          </div>

          <div class="px-4 w-3/4 flex flex-col mb-auto">
            <h3 transloco="payment.your-plan.title"></h3>
            <p class="text-grey" [transloco]="'payment.your-plan.' + didData?.countryName.toLowerCase().replaceAll(' ', '-') + '-' + packageID"></p>
          </div>
        </div>

        @if (authService.IsLogin()) {
          <app-credit-card (selectedPaymentId)="onSelectedSavedCard($event)"></app-credit-card>
        <!-- <div>
          <h3 class="pb-8" transloco="payment.your-saved-methods"></h3>

          <div class="flex flex-wrap items-center gap-4">
            @for (card of savedCards; track $index) {
            <div (click)="selectCard(card.creditCardId)"
              class="relative flex items-center rounded-2xl shadow-xl px-4 py-6 border border-dashed border-greyDark w-max hover:scale-105 hover:shadow-md cursor-pointer">
              <div class=" flex">
                <input type="checkbox" [checked]="isCardSelected(card.creditCardId)" (change)="selectCard(card.creditCardId)"
                  class="form-checkbox h-5 w-5 text-blue-600 mr-4" />
                @if(card.creditCardType !== '') {
                  <img [src]="'assets/images/icons/payment/' + card.creditCardType + '.svg'" alt="card type"
                    class="w-12 h-9 my-auto">
                }@else {
                  <img [src]="'assets/images/icons/payment/chip.svg'" alt="card type" class="w-12 h-9 my-auto">
                }
                <div class="px-4">
                  <h6 class=" text-sm font-semibold">•••• •••• {{ card.lastFourNumbers }}</h6>
                  <div class="text-xs text-grey">
                    <span transloco="profile-vn.billing.payment-methods.expires-on"></span>
                    {{ card.expirationMonth + '/' + card.expirationYear.slice(-2) }}
                  </div>
                </div>
              </div>
              @if(card.isPrimary === true) {
              <span>
                <img src="assets/images/icons/profile/star.svg" alt="" class="w-16 absolute -top-1.5 -right-3">
              </span>
              }
            </div>
            }
          </div>
        </div> -->
        }

        <div>
          <app-payment-form #paymentForm></app-payment-form>
          <app-billing-form #billingForm></app-billing-form>
        </div>

      </article>

      <article>
        <h3 class="border-b border-greyDark" transloco="payment.summary.title"></h3>
        <ul class="text-grey">
          <li class="flex py-4">
            <p class="flex flex-1 w-1/3" transloco="payment.summary.selected-plan"></p>
            <p class="w-2/3 text-end" [transloco]="'payment.summary.'  + didData?.countryName.toLowerCase().replaceAll(' ', '-') + '-' + packageCountryName">
              VNumber Mexico - American Virtual Number: 50% OFF!</p>
          </li>
          <li class="flex py-4">
            <p class="w-1/3" transloco="payment.summary.private-number"></p>
            <p class="w-1/3">+{{ didData?.did }}</p>
            <p class="w-1/3 text-end">${{ didData?.price }}</p>
          </li>
          <li class="flex py-4 text-2xl text-black font-medium border-y border-greyDark">
            <span class="flex flex-1" transloco="payment.summary.total"></span>
            <span> {{ totalPrice() }}</span>
          </li>
        </ul>
        <p class="text-grey text-end py-2" transloco="payment.summary.price-taxes"></p>
        <app-coupon-code (promoCode)="onCouponCodeSubmit($event)" [couponPrice]="couponPrice"
          (changeCouponPrice)="onRemoveCouponCode()"></app-coupon-code>

        <button
          class="{{ paymentCardForm.valid && billingAddressForm.valid ? 'transition delay-75 duration-200 hover:scale-110' : 'opacity-60' }} py-3 bg-orange w-full text-white uppercase rounded-full font-semibold"
          (click)="submitOrder()">
          <span transloco="btn-vn.place-order"></span>
        </button>

        <div class="p-12">
          <img src="assets/images/icons/payment/time.svg" alt="">
        </div>
      </article>
    </section>

    <app-info-pop-up *ngIf="showPopUp" [showPopUp]="showPopUp" (closePopUpEvent)="onClosePopUp()"
      [translate]="popUpMessage"></app-info-pop-up>

    <app-spinner *ngIf="isLoading"></app-spinner>
  </main>
}@else {
  <app-spinner></app-spinner>
}