import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { OldApiResponse } from '../models/base/OldApiResponse';

@Injectable({
  providedIn: 'root',
})
export class PackageInfoService {
  constructor(private http: HttpClient, private settings: SettingsService) {}

  async getPackageInfo<T>(packageId) {
    const res = this.http.post<OldApiResponse<T>>(
      `${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.GetPackageInfo}`,
      { packageid: packageId }
    );
    const data = await lastValueFrom(res);

    return data;
  }
}
