import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { ICountries } from 'src/app/services/models/countries.model';
import { IState } from 'src/app/services/models/states.model';
import { StatesService } from 'src/app/services/states/states.service';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-billing-form',
  standalone: true,
  imports: [TranslocoModule, ReactiveFormsModule],
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.scss'],
})
export class BillingFormComponent {
  billingForm = this.submitOrderService.submitForm.get(
    'billingForm'
  ) as FormGroup;
  formSubmitted: boolean = false;
  countries: ICountries[] = [];
  states: IState[] = [];
  selectedCountryId: string = '';
  selectedState: string = '';
  currentLang: string = "";

  constructor(
    private submitOrderService: SubmitOrderService,
    private translocoService: TranslocoService,
    private countriesService: CountriesService,
    private statesService: StatesService
  ) {
    this.currentLang = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.getCountries();
    this.billingForm.get('country')
    ?.valueChanges.subscribe((selectedCountry: string) => {
      this.selectedCountryId = selectedCountry;
    });
  }

  onSubmitBilling() {
    this.formSubmitted = true;

    if (this.billingForm.valid) {
      this.submitOrderService.setOrderData(this.billingForm.value);
      this.formSubmitted = false;
    }
  }

  onCountryChange(event: Event) {
    const countryCode = Number((event.target as HTMLSelectElement).value);
    this.getStates(countryCode);
  }

  async getCountries() {
    const res = await this.countriesService.getCountrySelection<ICountries[]>();

    if (res.isError) {
      console.error('ERROR: ', res.errorMessage);
      return;
    }

    if (res.isSuccessful) {
      this.countries = res.result;

      if (this.countries.length > 0) {
        this.selectedCountryId = this.currentLang === 'he' ? '101' : '218';
        this.billingForm.get('country')?.setValue(this.selectedCountryId);

        this.getStates(Number(this.selectedCountryId));
      }
    }
  }

  async getStates(countryId: number) {
    const res = await this.statesService.getStates<IState[]>(countryId);

    if (res.isSuccessful) {
      this.states = res.result;
    }
  }
}
