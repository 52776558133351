import { Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { TranslocoModule } from '@jsverse/transloco';
import { InfoPopUpComponent } from '../../core/shared/pop-up/info-pop-up/info-pop-up.component';
import { CommonModule } from '@angular/common';
import { ApiMessageCode } from '@app/services/models/base/ApiMessageCode';

@Component({
  selector: 'app-password-recover-page',
  standalone: true,
  imports: [CommonModule, InfoPopUpComponent, ReactiveFormsModule, TranslocoModule],
  templateUrl: './password-recover-page.component.html',
  styleUrl: './password-recover-page.component.scss'
})
export class PasswordRecoverPageComponent {

  authService = inject(AuthService);

  formSubmitted: boolean = false;
  showPopUp: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  valid: boolean = false;

  customerEmail: string = "";
  popUpMessage: string = "";
  currentLang: string = "";
  token: string = this.route.snapshot.params['token'];

  timer: number = 5;
  private intervalId: any;

  passwordForm = this.fb.group({
    newPassword: ['', [Validators.required, Validators.minLength(8), this.passwordValidator]],
    confirmPassword: ['', [Validators.required]],
  }, { validators: this.passwordMatchValidator() })

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.readToken()
  }

  passwordMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPassword = form.get('newPassword')?.value;
      const confirmPassword = form.get('confirmPassword')?.value;
      return newPassword === confirmPassword
        ? null
        : { passwordMismatch: true };
    };
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!/[A-Z]/.test(value)) {
      return { noUpperCase: true };
    }

    if (!/[A-Za-z]/.test(value)) {
      return { noLetter: true };
    }

    if (!/[0-9]/.test(value)) {
      return { noNumber: true };
    }
    return null;
  }

  readToken() {
    const jwtData = this.token.split('.')[1];

    if (jwtData) {
      const decodedJwtJsonData = window.atob(jwtData);
      const decodedJwtData = JSON.parse(decodedJwtJsonData);
      this.customerEmail = decodedJwtData['Email'];
    }
  }

  startTimer() {
    this.intervalId = setInterval(() => {
      this.timer--;
      if (this.timer === 0) {
        this.redirectToLogin();
      }
    }, 1000);
  }

  redirectToLogin() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    document.location.href = '/login';
  }

  async onSubmit() {
    this.formSubmitted = true;

    if (this.passwordForm.valid) {
      this.formSubmitted = false;
      
      try {
        const res = await this.authService.validateResetPassword(this.token, this.passwordForm.get('newPassword').value);
        
        if (res.isSuccessful && res.result) {
          this.valid = true;

          // if (this.authService.IsLogin()) {
          //   this.authService.clearLocalStorage();
          // }
          this.startTimer();
        }
      } catch (error) {
        console.log(error)
        const apiMessageCode = ApiMessageCode.MessageCode
        const message = error.error.messages[0]

        if (message.code === apiMessageCode.tokenExpired) {
          this.showPopUp = true;
          this.popUpMessage = 'token-expired-or-invalid';
        }
      }
    }
  }
}
