import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqAccordionComponent } from 'src/app/core/shared/faq/faq-accordion/faq-accordion.component';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-faq-page',
  standalone: true,
  imports: [CommonModule, FaqAccordionComponent, TranslocoModule],
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent {

}
