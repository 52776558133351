import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-carousel.component.html',
  styleUrl: './image-carousel.component.scss'
})
export class ImageCarouselComponent {
  images: string[] = [
    'assets/images/icons/dialogue/1.svg',
    'assets/images/icons/dialogue/2.svg',
    'assets/images/icons/dialogue/3.svg',
    'assets/images/icons/dialogue/4.svg',
    'assets/images/icons/dialogue/5.svg',
    'assets/images/icons/dialogue/6.svg',
    'assets/images/icons/dialogue/7.svg',
    'assets/images/icons/dialogue/8.svg',
  ];
  currentIndex: number = 0;
  private intervalId: any;

  ngOnInit(): void {
    this.startCarousel();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

  startCarousel(): void {
    this.intervalId = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }, 2000); // Cambia cada 3 segundos
  }
}
