import { Component, inject, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { CreditCardComponent } from '@app/core/shared/profile/credit-card/credit-card.component';
import { AboutPageComponent } from '../../../../pages/about-page/about-page.component';
import { AddCreditService } from '@app/services/add-credit/add-credit.service';
import { AuthService } from '@app/services/auth/auth.service';
import { PaymentFormComponent } from '../../payment/payment-form/payment-form.component';
import { BillingFormComponent } from '../../payment/billing-form/billing-form.component';
import { PaymentMethodsService } from '@app/services/payment-methods/payment-methods.service';
import { SubmitOrderService } from '@app/services/submit-order/submit-order.service';
import { RecaptchaService } from '@app/services/recatpcha/recaptcha.service';
import { SpinnerComponent } from '../../loading/spinner/spinner.component';
import { InfoPopUpComponent } from '../../pop-up/info-pop-up/info-pop-up.component';
import { AccountInfoService } from '../../../../services/account-info/account-info.service';

@Component({
  selector: 'app-add-credit',
  standalone: true,
  imports: [
    AboutPageComponent,
    CreditCardComponent,
    FormsModule,
    InfoPopUpComponent,
    SpinnerComponent,
    PaymentFormComponent,
    BillingFormComponent,
    TranslocoModule,
  ],
  templateUrl: './add-credit.component.html',
  styleUrl: './add-credit.component.scss',
})
export class AddCreditComponent {
  @ViewChild('paymentForm') paymentForm: PaymentFormComponent;
  @ViewChild('billingForm') billingForm: BillingFormComponent;

  addCreditService = inject(AddCreditService);
  authService = inject(AuthService);
  paymentMethodsService = inject(PaymentMethodsService);
  submitOrderService = inject(SubmitOrderService);
  recaptchaService = inject(RecaptchaService);
  accountInfoService = inject(AccountInfoService)

  paymentID: number = 0;
  customAmount: string = '';

  popUpMessage: string = '';
  showPopUp: boolean = false;

  confirmationID: number = 0;
  showConfirmationPopUp: boolean = false;
  isNewMethod: boolean = false;

  termsAndConditions: boolean = true;
  isPrimary: boolean = false;
  keepCard: boolean = true;
  isLoading: boolean = false;

  creditCardForm = this.submitOrderService.submitForm.get(
    'paymentForm'
  ) as FormGroup;
  addressCardForm = this.submitOrderService.submitForm.get(
    'billingForm'
  ) as FormGroup;

  constructor() {}

  ngOnInit(): void {
    // this.creditCardForm.patchValue({
    //   cardNumber: '4242424242424242',
    //   cardHolder: 'sfdfsdf sdfsdfds',
    //   cardExpirationMM: '09',
    //   cardExpirationYY: '2026',
    //   cardCVV: '234',
    // });

    // this.addressCardForm.patchValue({
    //   city: 'Montevideo',
    //   state: '4510',
    //   zipCode: '11200',
    //   address: '2119 Colonia',
    //   country: '217',
    // })
  }

  selectAmount(amount: string): void {
    this.customAmount = amount;
  }

  onSelectedPaymentMethod(paymentId: number) {
    this.paymentID = paymentId;
  }

  onCloseConfirmationPopUp() {
    this.showConfirmationPopUp = false;
    this.accountInfoService.onCreditAdded(true);
    this.customAmount = "";
  }

  onClosePopUp() {
    this.showPopUp = false;
  }

  numberOnly(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = value.slice(0, 2);
    }

    const numValue = parseInt(value, 10);
    if (numValue > 99) {
      value = '99';
    } else if (numValue === 0 && value !== '') {
      value = '1';
    }

    this.customAmount = value;
    input.value = this.customAmount;
  }

  shouldDisableButton(): boolean {
    if (this.customAmount === '') return true;
    
    if (!this.termsAndConditions) return true;

    if (this.isNewMethod) {
      return this.creditCardForm.invalid || this.addressCardForm.invalid;
    } else {
      return this.paymentID === 0;
    } 
  }

  async addPaymentMethod() {
    const jsonData = {
      creditCardNumber: this.creditCardForm.get('cardNumber').value,
      cardHolder: this.creditCardForm.get('cardHolder').value,
      expirationMonth: this.creditCardForm.get('cardExpirationMM').value,
      expirationYear: this.creditCardForm.get('cardExpirationYY').value,
      securityCode: this.creditCardForm.get('cardCVV').value,
      isPrimary: this.isPrimary,
      address: {
        countryID: this.addressCardForm.get('country').value,
        city: this.addressCardForm.get('city').value,
        state: this.addressCardForm.get('state').value,
        zipCode: this.addressCardForm.get('zipCode').value,
        address: this.addressCardForm.get('address').value,
        stateID: this.addressCardForm.get('state').value,
      },
    };

    try {
      const res = await this.paymentMethodsService.createCreditCard(jsonData);

      if (res.isSuccessful) {
      }
    } catch (error) {}
  }

  async addCredit() {
    this.isLoading = true;
    const amountToAdd = Number(this.customAmount) || 0;
    const auth = this.authService.getCurrentUser();
    let jsonData;
    const token = await this.recaptchaService.executeRecaptcha(
      'vNumberPurchase'
    );

    if (this.isNewMethod) {
      this.paymentForm.onSubmitPayment();
      this.billingForm.onSubmitBilling();

      if (this.keepCard) {
        this.addPaymentMethod();
      }

      jsonData = {
        CreditCardNumber: this.creditCardForm.get('cardNumber').value,
        CardHolder: this.creditCardForm.get('cardHolder').value,
        ExpirationMonth: Number(this.creditCardForm.get('cardExpirationMM').value),
        ExpirationYear: Number(this.creditCardForm.get('cardExpirationYY').value),
        SecurityCode: this.creditCardForm.get('cardCVV').value,
        IsPrimary: this.isPrimary ? 1 : 0,
        City: this.addressCardForm.get('city').value,
        State: this.addressCardForm.get('state').value,
        Zip: this.addressCardForm.get('zipCode').value,
        Address: this.addressCardForm.get('address').value,
        CountryNumber: this.addressCardForm.get('country').value,
        IsSaveCard: this.keepCard ? 1 : 0,
        Amount: amountToAdd,
        SubID: auth.firstSubID,
        CustomerID: auth.customerId,
        recaptcha: token,
      };
    } else {
      jsonData = {
        PaymentId: this.paymentID,
        Amount: amountToAdd,
        SubID: auth.firstSubID,
        CustomerID: auth.customerId,
        recaptcha: token,
      };
    }

    try {
      const res = await this.addCreditService.addCredit(jsonData);

      if (res.response_code === 200) {
        this.isLoading = false;
        this.confirmationID = res.confirmationId;
        this.showConfirmationPopUp = true;
      }
    } catch (error) {
      this.isLoading = false;
      this.showPopUp = true;
      this.popUpMessage = 'server-error';
      console.log(error);
    }
  }
}
