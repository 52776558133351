<section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
  <h2 class="flex flex-col text-2xl mb-6" [innerHTML]="'profile-vn.add-credit.title' | transloco"></h2>

  <article class="">
    <h3 class="text-base font-medium pt-4" transloco="profile-vn.add-credit.subtitle"></h3>

    <div class="flex flex-wrap gap-4 py-12">
      @for (amount of ['15', '25', '50']; track $index) {
      <button (click)="selectAmount(amount)" [class]="{'border-orange': customAmount === amount}"
        class=" flex items-center btn-effect text-green py-2 px-6 sm:px-10 border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 focus:outline-none focus:ring-1 focus:ring-orange">
        <span class="text-lg font-semibold">$ {{amount}}</span>
      </button>
      }
      <div class="relative flex items-center text-green text-lg font-semibold ">
        <input type="text" [(ngModel)]="customAmount" placeholder="-" maxlength="2" (input)="numberOnly($event);"
          class="w-24 py-3 ltr:pl-11 rtl:pr-11 font-semibold bg-greyLight rounded-xl shadow-sm focus:outline-none focus:ring-1 focus:ring-orange">
        <span class="absolute left-7">$</span>
      </div>
    </div>

    <div>
      <div>
        <h2 class="flex flex-col text-2xl mb-6 font-semibold" transloco="payment.use-saved-methods"></h2>
        <app-credit-card (selectedPaymentId)="onSelectedPaymentMethod($event)"></app-credit-card>
      </div>
      <div class="relative flex flex-col mb-6">
        <h2 class="flex flex-col text-2xl my-6 font-semibold" transloco="payment.add-new-method"></h2>
        @if (isNewMethod) {
        <span (click)="this.isNewMethod = false"
          class="absolute right-2 top-6 cursor-pointer active:scale-90 bg-white rounded-full z-10">
          <img src="assets/images/icons/close-popup.svg" alt="" class="w-8"></span>
        <app-payment-form #paymentForm></app-payment-form>
        <app-billing-form #billingForm></app-billing-form>

        <div class="flex flex-col mt-8 gap-2">
					<div class="flex items-center">
						<input type="checkbox" id="isDefaultCard" [(ngModel)]="isPrimary"
							class="border-greyDark mx-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded border-[0.125rem] border-solid outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:mx-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent">
						<label for="isDefaultCard" class="text-sm" transloco="profile-vn.billing.payment-methods.default-card">
						</label>
					</div>
					<div class="flex items-center">
						<input type="checkbox" id="termsAndConditions" [(ngModel)]="termsAndConditions"
							class="{{termsAndConditions ? 'border-danger': 'border-greyDark'}} mx-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded border-[0.125rem] border-solid  outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:mx-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent">
						<label for="termsAndConditions" class="text-sm"
							[innerHTML]="'profile-vn.billing.payment-methods.terms-conditions' | transloco">
						</label>
					</div>
          <div class="flex items-center">
            <input type="checkbox" id="keepCard" [(ngModel)]="keepCard"
              class="mx-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded border-[0.125rem] border-solid  outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:mx-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent">
            <label for="keepCard" class="text-sm"
              [innerHTML]="'profile-vn.billing.payment-methods.keep-card' | transloco">
            </label>
          </div>
				</div>
        }@else {
        <button (click)="isNewMethod = true" class="">
          <img src="assets/images/icons/add-circle.svg" alt="" class="w-8 hover:scale-105">
        </button>
        }
      </div>
    </div>

    <button (click)="addCredit()" [disabled]="shouldDisableButton()"
      class="{{ shouldDisableButton() ? 'opacity-50' : 'hover:scale-105 transition duration-200' }} w-full bg-orange text-white uppercase font-semibold py-3 px-4 rounded-lg  "
      transloco="btn-vn.add-credit"></button>

  </article>

</section>

@if (isLoading) {
  <app-spinner></app-spinner>
  }

@if (showConfirmationPopUp) {
<div
  class="fixed inset-0 bg-white bg-opacity-75 flex flex-col items-center z-50 transition-opacity duration-500 ease-in-out scrollbar-sec overflow-y-auto">
  <span (click)="onCloseConfirmationPopUp()"
    class="absolute right-2 top-2 mt-16 sm:mt-20 cursor-pointer active:scale-90 bg-white rounded-full z-10">
    <img src="assets/images/icons/close-popup.svg" alt="" class="w-8 sm:w-12"></span>

  <div class="flex flex-col items-center my-auto">
    <div class="bg-white rounded-xl px-12 py-6 border-2 border-orange">
      <section class="flex justify-center">
        <div class="flex flex-col w-fit pb-6">
          <img src="assets/images/icons/plans/5vnumber.svg" alt="" class="w-12 ml-auto">
          <h3 class="text-center px-12 py-4 font-semibold" transloco="profile-vn.add-credit.done-title"></h3>
          <img src="assets/images/icons/FAQ_1vnumber.svg" alt="" class="w-20 ">
        </div>
      </section>
      <section class="flex">
        <img src="assets/images/icons/cel-hand.svg" alt="" class="w-36">
        <article>
          <div class="py-2">
            <span class="" transloco="profile-vn.add-credit.recharged-amount"></span>
            <div class="flex gap-1">
              <img src="assets/images/icons/profile/positive-balance.svg" alt="icon" class="w-8">
              <span class="text-green text-2xl font-medium">$ {{ customAmount }}</span>
            </div>
          </div>
          
          <div class="flex gap-2">
            <span transloco="profile-vn.add-credit.confirmation-number"></span>
            <span>{{ confirmationID }}</span>
          </div>
        </article>
      </section>
    </div>
  </div>
</div>
}

@if (showPopUp) {
  <app-info-pop-up
  [showPopUp]="showPopUp" 
  (closePopUpEvent)="onClosePopUp()" 
  [translate]="popUpMessage">
</app-info-pop-up>
}