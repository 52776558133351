<section class="flex flex-col gap-y-4 w-full">

  <div class="flex justify-center items-center py-12">
    <span class="flex flex-1 border"></span>
    <span class="px-3 font-semibold">o</span>
    <span class="flex flex-1 border"></span>
  </div>

  <span transloco="login.social-log-subtitle" class="font-semibold"></span>

  <article class="flex items-center gap-6 border rounded p-3 w-full">
    <img src="assets/images/icons/login/apple.svg" alt="" class="w-6">
    <span transloco="login.sign-apple" class="text-grey"></span>
  </article>

  <article class="flex items-center gap-6 border rounded p-3 w-full">
    <img src="assets/images/icons/login/google.svg" alt="" class="w-6">
    <span transloco="login.sign-google" class="text-grey"></span>
  </article>

  <article class="flex items-center gap-6 border rounded p-3 w-full">
    <img src="assets/images/icons/login/facebook.svg" alt="" class="w-6">
    <span transloco="login.sign-facebook" class="text-grey"></span>
  </article>

  <div class="mt-4 text-grey text-xs pb-24" [innerHTML]="'login.accept-terms-policy' | transloco"></div>

</section>