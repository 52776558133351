import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardCarouselComponent } from 'src/app/core/shared/home/card-carousel/card-carousel.component';
import { BtnPrimaryComponent } from 'src/app/core/shared/buttons/btn-primary/btn-primary.component';
import { ContactFormComponent } from 'src/app/core/shared/home/contact-form/contact-form.component';
import { FaqAccordionComponent } from 'src/app/core/shared/faq/faq-accordion/faq-accordion.component';
import { SearchCountriesComponent } from 'src/app/core/shared/search-countries/search-countries.component';
import { TranslocoModule } from '@jsverse/transloco';
import { ImageCarouselComponent } from 'src/app/core/shared/home/image-carousel/image-carousel.component';
import { TestimonialCarouselComponent } from 'src/app/core/shared/home/testimonial-carousel/testimonial-carousel.component';
import { AdvantagesCardComponent } from 'src/app/core/shared/home/advantages-card/advantages-card.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    CardCarouselComponent,
    SearchCountriesComponent,
    BtnPrimaryComponent,
    ContactFormComponent,
    FaqAccordionComponent,
    ImageCarouselComponent,
    AdvantagesCardComponent,
    TestimonialCarouselComponent,
    TranslocoModule,
    RouterModule
  ],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {}
