import { Component, ElementRef, HostListener, inject } from '@angular/core';
import { NavigationEnd, RouterModule, Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { RandomColorPipe } from 'src/app/pipes/random-color.pipe';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserInformation } from 'src/app/services/models/user-information.model';

@Component({
  selector: 'app-profile-dropdown',
  standalone: true,
  imports: [TranslocoModule, RouterModule, RandomColorPipe],
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent {
  dropdownVisible = false;
  authService = inject(AuthService)
  userData: UserInformation;

  constructor(private el: ElementRef, private router: Router,) {
    this.router.events.subscribe(value => {
      if(value instanceof NavigationEnd)    
      if (this.dropdownVisible) {
        this.dropdownVisible = false;
      }
      });
  }

  ngOnInit(): void {
    this.userData = this.authService.getCurrentUser();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {

    if (this.dropdownVisible) {
      if (!this.el.nativeElement.contains(event.target)) {
        this.dropdownVisible = false;
      }
    }
  }

  logout() {
    this.authService.logout()
  }
}
