<main id="homePage" class="py-10">
  <section class="flex flex-col items-center px-5 mlg:flex-row justify-center gap-24 pb-10">
    <div class="w-full lg:w-[400px] smd:py-10">
      <article class="animate-fade-right smd:px-4">
        <h1 class="hidden">Get Your Virtual Number with VNumber</h1>
        <h2 class="py-5 leading-none" [innerHTML]="'home.title-vn' | transloco"></h2>
        <p class=" text-grey pb-12 pr-6 " transloco="home.description-vn"></p>
      </article>

      @defer { <app-search-countries></app-search-countries> }
    </div>
    <div class="relative max-w-[660px] animate-fade-left">
      <img src="assets/images/icons/topvnumber.svg" alt="" class="w-[340px] md:w-[660px]">
      <div class="absolute top-[3.1rem] md:top-24 left-[4.5rem] md:left-[8.8rem] transform -translate-x-1/2 -translate-y-1/2 w-[200px] md:w-full">
        <app-image-carousel></app-image-carousel>
      </div>
    </div>
  </section>

  <!-- ADVANTAGES -->
  <section class=" px-5">
    <h2 class="text-center py-10 font-semibold" [innerHTML]="'home.advantages.title' | transloco"></h2>
    <div class="hidden smd:flex flex-col items-center smd:flex-row justify-center gap-4 py-10">
      <div class="flex flex-col gap-10">
        <app-advantages-card position="1" title="card-1-title" description="card-1-text"></app-advantages-card>
        <app-advantages-card position="2" title="card-2-title" description="card-2-text"></app-advantages-card>
        <app-advantages-card position="3" title="card-3-title" description="card-3-text"></app-advantages-card>
      </div>

      <div class="hidden lg:flex">
        <img src="assets/images/icons/Featuresvnumber1.svg" alt="" class="w-[580px]">
      </div>

      <div class="flex flex-col gap-10">
        <app-advantages-card position="4" title="card-4-title" description="card-4-text"></app-advantages-card>
        <app-advantages-card position="5" title="card-5-title" description="card-5-text"></app-advantages-card>
        <app-advantages-card position="6" title="card-6-title" description="card-6-text"></app-advantages-card>
      </div>

    </div>

    <div class="flex smd:hidden">
      <app-card-carousel></app-card-carousel>
    </div>

    <div class="flex justify-center py-10">
      <app-btn-primary text="get-your-number" link="/get-your-number"></app-btn-primary>
    </div>
  </section>

  <!-- How to receive SMS -->
  <section class="px-5">
    <h2 class="text-center py-10 font-semibold" [innerHTML]="'home.how-to-receive-sms.title' | transloco"></h2>

    <div class="flex flex-col lg:flex-row justify-center gap-10 mx-auto pt-20 py-10 w-fit">
      <article class="relative shadow-full rounded-3xl p-6 w-full sm:max-w-[300px] ">
        <img src="assets/images/icons/SMS_vnumber.svg" alt="" class="w-72 absolute -top-28 -left-4 sm:-left-6 z-20">
        <img src="assets/images/icons/smsvnumber.svg" alt="" class="w-10 absolute left-0 top-5 z-10">
        <p class="text-lg py-4 mt-4" transloco="home.how-to-receive-sms.card-1-text"></p>
      </article>
      <article class="relative shadow-full rounded-3xl p-6 w-full sm:max-w-[300px] ">
        <img src="assets/images/icons/sms_1vnumber.svg" alt="" class="w-10 absolute left-0 top-5">
        <p class="text-lg py-4 mt-4" transloco="home.how-to-receive-sms.card-2-text"></p>
      </article>
      <article class="relative shadow-full rounded-3xl p-6 w-full sm:max-w-[300px] ">
        <img src="assets/images/icons/sms_2vnumber.svg" alt="" class="w-10 absolute left-0 top-5">
        <p class="text-lg py-4 mt-4" transloco="home.how-to-receive-sms.card-3-text"></p>
      </article>
    </div>

    <!-- <div class="flex justify-center py-10">
      <app-btn-primary text="Get yours now!" link="/choose-your-number"></app-btn-primary>
    </div> -->
  </section>

  <!-- About us -->
  <section class="px-5">
    <h2 class="text-center py-10 font-semibold" [innerHTML]="'home.about-us.title' | transloco"></h2>
    <div  style="direction: ltr;" class="relative flex flex-col-reverse items-center lg:flex-row lg:justify-center gap-0 pb-16 max-w-[960px] mx-auto">
       @defer { <app-testimonial-carousel></app-testimonial-carousel> }
      <img src="assets/images/icons/testimonial_1vnumber.svg" alt="" class="w-40 lg:w-60">
    </div>
  </section>

  <!-- FAQ -->
  <section class="flex flex-col items-center px-5">
    <h2 class="text-center py-10 font-semibold" transloco="home.faq-title"></h2>
    <div class="relative border border-greyDark rounded-3xl p-6 shadow-full mt-6 lg:w-[946px]">
      <img src="assets/images/icons/FAQ_3vnumber.svg" alt="" class="w-36 absolute left-20 -top-[52px] z-20 ">
      <app-faq-accordion faqSrc="Home"></app-faq-accordion>
    </div>
  </section>

  <!-- CONTACT -->
  <section id="contactUs" class=" px-5">
    <h2 class="text-center pt-10 pb-3 font-semibold" transloco="home.contact-us.title"></h2>
    <p class="text-grey text-center smd:pb-12" transloco="home.contact-us.subtitle"></p>

    <div class="flex justify-center">
      <div class="flex flex-col items-center h-full my-auto">
        <img src="assets/images/icons/FAQvnumber.svg" alt="" class="hidden smd:block w-16 pb-8">
        <img src="assets/images/icons/FAQ_1vnumber.svg" alt="" class="hidden smd:block w-36">
      </div>
      <app-contact-form></app-contact-form>
      <img src="assets/images/icons/FAQ_2vnumber.svg" alt="" class="hidden smd:block w-32 pt-36">
    </div>

    <article class="flex flex-col md:flex-row justify-evenly py-10 text-grey text-xl font-medium">
      <div class="flex flex-col items-center">
        <img src="assets/images/icons/contact_1vnumber.svg" alt="" class="w-12 p-3 rounded-full shadow-full">
        <a href="tel:1-877-993-9770" class=" py-4">1-877-993-9770</a>
      </div>
      <div class="flex flex-col items-center">
        <img src="assets/images/icons/contactvnumber.svg" alt="" class="w-12 p-3 rounded-full shadow-full">
        <p class="py-4" [innerHTML]="'choose-number.need-more-info.whatsapp' | transloco"></p>
      </div>
    </article>
  </section>
</main>