<div class="relative w-full bg-white pb-8 sm:mx-auto xl:min-w-[800px] sm:rounded-lg">
  <div class="mx-auto px-5">
    <div *ngFor="let item of faqItems | slice:0:(faqSrc === 'Home' ? 7 : 15)" class="mx-auto grid max-w-4xl border-b">
      <div class="py-8">
        <details class="group">
          <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
            <span [innerHTML]="item.q | transloco" class="transition group-open:text-orange font-semibold"></span>
            <span class="transition group-open:rotate-180 sm:ml-4">
              <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                <path d="M6 9l6 6 6-6"></path>
              </svg>
            </span>
          </summary>
          <p class="group-open:animate-fadeIn mt-3 text-neutral-600" [innerHTML]="item.a | transloco">
          </p>
        </details>
      </div>
    </div>
  </div>
</div>