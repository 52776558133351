import { Component, Output, EventEmitter } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-btn-logout',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './btn-logout.component.html',
  styleUrl: './btn-logout.component.scss',
})
export class BtnLogoutComponent {
  @Output() onLogOut: EventEmitter<void> = new EventEmitter<void>();

  constructor () {}

  logout() {
    console.log("logout")
    this.onLogOut.emit();
  }

  ngOnInit(): void {}
}
