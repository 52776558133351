import { Component, Input } from '@angular/core';
import { PhoneFormatPipe } from '@app/pipes/phone-format.pipe';
import { RandomColorPipe } from '@app/pipes/random-color.pipe';
import { ICallHistory } from '@app/services/models/call-history.model';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-call-history-list',
  standalone: true,
  imports: [PhoneFormatPipe, RandomColorPipe, TranslocoModule],
  templateUrl: './call-history-list.component.html',
  styleUrl: './call-history-list.component.scss'
})
export class CallHistoryListComponent {
  @Input() calls: ICallHistory[] = [];
  @Input() title: string = '';
}
