import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CountriesService } from '@app/services/countries/countries.service';
import { ICountries } from '@app/services/models/countries.model';
import { IState } from '@app/services/models/states.model';
import { StatesService } from '@app/services/states/states.service';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AccessNumbersService } from '../../../../../services/access-numbers/access-numbers.service';
import { IAccessNumbers } from '@app/services/models/access-numbers.model';
import { PhoneFormatPipe } from '@app/pipes/phone-format.pipe';

@Component({
  selector: 'app-access-numbers',
  standalone: true,
  imports: [PhoneFormatPipe, ReactiveFormsModule, TranslocoModule],
  templateUrl: './access-numbers.component.html',
  styleUrl: './access-numbers.component.scss',
})
export class AccessNumbersComponent {
  accessNumbersForm = this.fb.group({
    country: [0, Validators.required],
    state: [0, Validators.required],
    // areaCode: ['', Validators.required],
  });

  accessNumbersService = inject(AccessNumbersService);
  countriesService = inject(CountriesService);
  statesService = inject(StatesService);
  translocoService = inject(TranslocoService);

  countries: ICountries[] = [];
  states: IState[] = [];
  selectedCountryId: number;
  selectedState: string = '';
  currentLang: string = '';
  formSubmitted: boolean = false;
  numbers: IAccessNumbers[] = [];

  constructor(private fb: FormBuilder) {
    this.currentLang = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.getCountries();

    if (this.accessNumbersForm.touched) {
      
    }
  }

  async onSubmitAccessNumbers() {
    this.formSubmitted = true;
    let countryID = this.accessNumbersForm.get('country').value
    let countryPrefix = this.countries.find(i=>i.countryID === Number(countryID))

    if (this.accessNumbersForm.valid) {
      try {
        const res = await this.accessNumbersService.getAccessNumbers<IAccessNumbers[]>(
          Number(countryPrefix.prefix),
          this.accessNumbersForm.get('state').value,
        );

        if (res.isSuccessful && res.result) {
          this.numbers = res.result;
          this.formSubmitted = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  onCountryChange(event: Event) {
    const countryCode = Number((event.target as HTMLSelectElement).value);
    this.getStates(countryCode);
  }

  async getCountries() {
    const res = await this.countriesService.getCountrySelection<ICountries[]>();

    if (res.isError) {
      console.error('ERROR: ', res.errorMessage);
      return;
    }

    if (res.isSuccessful) {
      this.countries = res.result;

      if (this.countries.length > 0) {
        this.selectedCountryId = this.currentLang === 'he' ? 101 : 218;
        this.accessNumbersForm.get('country')?.setValue(this.selectedCountryId);
        this.getStates(Number(this.selectedCountryId));
      }
    }
  }

  async getStates(countryId: number) {
    const res = await this.statesService.getStates<IState[]>(countryId);

    if (res.isSuccessful) {
      this.states = res.result;
    }
  }
}
