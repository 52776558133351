import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { ApiResponse } from '../models/base/ApiResponse';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountInfoService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  async getAccountInfo<T>() {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetAccountInfo}`)
    const data = lastValueFrom(res)
    return data
  }
  
  async updateAccountInfo<T>(patchOperations: any[]) {
    const res = this.http.patch<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetAccountInfo}`, patchOperations)
    const data = lastValueFrom(res)
    return data
  }

  async verifyEmailChange<T>(customerData: any) {
    const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.VerifyEmailChange}`, customerData)
    const data = lastValueFrom(res)
    return data
  }

  async validateCustomerEmail<T>(token: any) {

    let params = new HttpParams().set('token', token)

    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.ValidateCustomerEmail}`, {params: params})
    const data = lastValueFrom(res)
    return data
  }
}
