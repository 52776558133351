import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Collapse, initTWE } from 'tw-elements';
import { TranslocoModule } from '@jsverse/transloco';
// import { FAQItem } from 'src/app/models/faq.model';

interface FAQItem {
  q: string;
  a: string;
}

@Component({
  selector: 'app-faq-accordion',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './faq-accordion.component.html',
  styleUrls: ['./faq-accordion.component.scss'],
})
export class FaqAccordionComponent {
  @Input() faqSrc: string = '';

  faqItems: FAQItem[] = [
    {
      q: 'faq-vn.question-one',
      a: 'faq-vn.answer-one'
    },
    {
      q: 'faq-vn.question-two',
      a: 'faq-vn.answer-two',
    },
    {
      q: 'faq-vn.question-three',
      a: 'faq-vn.answer-three',
    },
    {
      q: 'faq-vn.question-four',
      a: 'faq-vn.answer-four',
    },
    {
      q: 'faq-vn.question-five',
      a: 'faq-vn.answer-five',
    },
    {
      q: 'faq-vn.question-six',
      a: 'faq-vn.answer-six',
    },
    {
      q: 'faq-vn.question-seven',
      a: 'faq-vn.answer-seven',
    },
    {
      q: 'faq-vn.question-eight',
      a: 'faq-vn.answer-eight',
    },
    {
      q: 'faq-vn.question-nine',
      a: 'faq-vn.answer-nine',
    },
    {
      q: 'faq-vn.question-ten',
      a: 'faq-vn.answer-ten',
    },
    {
      q: 'faq-vn.question-eleven',
      a: 'faq-vn.answer-eleven',
    },
    {
      q: 'faq-vn.question-twelve',
      a: 'faq-vn.answer-twelve',
    }
  ];

  constructor() {}

  faqSelected: number = -1;
  
  collapseFaq(index: number) {
    if (index !== this.faqSelected) {
      this.faqSelected = index;
    } else {
      this.faqSelected = -1;
    }
  }

  ngOnInit(): void {
    initTWE({ Collapse });
  }
}


