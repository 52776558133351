import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FlagsPipe } from '@app/pipes/flags.pipe';
import { PhoneFormatPipe } from '@app/pipes/phone-format.pipe';
import { CallerIdService } from '@app/services/caller-id/caller-id.service';
import { ICallerID, ICallerIDResult } from '@app/services/models/caller-id.model';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-my-cli',
  standalone: true,
  imports: [FlagsPipe, FormsModule, PhoneFormatPipe, ReactiveFormsModule, TranslocoModule],
  templateUrl: './my-cli.component.html',
  styleUrl: './my-cli.component.scss',
})
export class MyCliComponent {
  @ViewChild('nameInput') nameInput!: ElementRef;
  @ViewChild('prefixInput') prefixInput!: ElementRef;
  @ViewChild('prefix1Input') prefix1Input!: ElementRef;

  callerIdService = inject(CallerIdService);

  numbers: ICallerID[] = [];

  isAddNewPhone: boolean = false;
  formSubmitted: boolean = false;
  deleteConfirmationId: number | null = null;

  // newCallerPrefix: string = "";
  // newCallerNumber: string = "";
  // newCallerName: string = "";

  maxCallerIDsAllowed: number = 0;
  editingId: number | null = null;

  newCLIForm = this.fb.group({
    prefix: ['', Validators.required],
    phone: ['', Validators.required],
    confirmPrefix: ['', Validators.required],
    confirmPhone: ['', Validators.required],
    name: ['']
  }, { validators: this.phoneMatchValidator() })

  editCLIForm = this.fb.group({
    prefix: ['', Validators.required],
    phone: ['', Validators.required],
    confirmPrefix: ['', Validators.required],
    confirmPhone: ['', Validators.required],
    name: [''],
    id: [0]
  }, { validators: this.phoneMatchValidator() })

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.getCallerIdNumber();
  }

  phoneMatchValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const newPhone = form.get('prefix')?.value + form.get('phone')?.value;
      const confirmPhone = form.get('confirmPrefix')?.value + form.get('confirmPhone')?.value;;
      return newPhone === confirmPhone ? null : { phoneMismatch: true };
    };
  }

  // Only allow adding if the maximum has not been reached.
  canAddMorePhones(): boolean {
    return this.numbers.length < this.maxCallerIDsAllowed;
  }

  // Only allow deleting if there is more than 1 number
  canDeletePhone(): boolean {
    return this.numbers.length > 1;
  }

  confirmDelete(id: number) {
    this.deleteConfirmationId = id
  }

  addNewPhone() {
    if (this.canAddMorePhones()) {
      this.isAddNewPhone = true;
      setTimeout(() => {
        if (this.prefix1Input) {
          this.prefix1Input.nativeElement.focus(); // Focusing the input
        }
      }, 0);
    }
  }

  cancelAddNewPhone() {
    this.isAddNewPhone = false;
    this.newCLIForm.reset()
  }
  
  startEditing(id: number) {
    this.editingId = id;

    // Find the number to be edited
    const numberToEdit = this.numbers.find(num => num.id === id);
    
    if (numberToEdit) {
      // Extracts the prefix and number from the callerId
      // const callerIdMatch = numberToEdit.callerId.match(/^(\+?\d+)(\d+)$/);
      // if (callerIdMatch) {
      //   const [, prefix, phone] = callerIdMatch;
        
        // Updates the form with all values
        this.editCLIForm.patchValue({
          prefix: numberToEdit.prefix,
          phone: numberToEdit.callerId.replace(numberToEdit.prefix, ''),
          // confirmPrefix: prefix,
          // confirmPhone: phone,
          name: numberToEdit.comment,
          id: numberToEdit.id
        });
    }
    
    this.editCLIForm.patchValue({ 
      id: id 
    });

    setTimeout(() => {
      if (this.prefixInput) {
        this.prefixInput.nativeElement.focus(); // Focusing the input
      }
    }, 0); // Calling focus after the DOM has been updated
  }
  
  stopEditing() {
    this.editingId = null;
  }

  async onSubmitNewPhone() {
    const { prefix, phone, name } = this.newCLIForm.value
    this.formSubmitted = true

    if (this.newCLIForm.valid) {

      const jsonData = {
        newCallerId: prefix + phone,
        comments: name
      }

      try {
        const res = await this.callerIdService.addCLINumber(jsonData)

        if (res.isSuccessful && res.result) {
          this.getCallerIdNumber();
          this.newCLIForm.reset()
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.cancelAddNewPhone()
      }
    }
  }
  
  async getCallerIdNumber() {
    try {
      const res = await this.callerIdService.getCallerIds<ICallerIDResult>();

      if (res.isSuccessful && res.result) {
        this.numbers = res.result.callerIds
        this.maxCallerIDsAllowed = res.result.maxCallerIDsAllowed;
      }
    } catch (error) {
      console.log(error)
    }
  }

  async updateCallerId() {
    this.formSubmitted = true

    if (this.editCLIForm.valid) {
      const { prefix, phone, name, id } = this.editCLIForm.value

      const jsonData = {
        id: id,
        newCallerId: prefix + phone,
        comments: name
      }
      
      try {
        const res = await this.callerIdService.updateCLINumber(jsonData)
        
        if (res.isSuccessful && res.result) {
          this.getCallerIdNumber();
          this.stopEditing();
        }
      } catch (error) {
        
      }
    }
  }
    
  async deleteCallerId(id: number) {
    if (this.canDeletePhone()) {
      try {
        const res = await this.callerIdService.deleteCLINumber(id);
        if (res.isSuccessful) {
          this.getCallerIdNumber();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
