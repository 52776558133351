import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { OldApiResponse } from '../models/base/OldApiResponse';
import { lastValueFrom } from 'rxjs';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { IAddCredit } from '../models/add-credit.model';

@Injectable({
  providedIn: 'root'
})
export class AddCreditService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  async addCredit(credit: any) {
    const res = this.http.post<IAddCredit>(`${this.settings.getBaseUrlApi}/${ControllerApiList.WebApi.AddCredit}`, credit);
    const data = lastValueFrom(res);
    return data
  }
}
