import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NeedMoreInfoFormComponent } from 'src/app/core/shared/choose-your-number/need-more-info-form/need-more-info-form.component';
import { GetDIDsService } from '../../services/getDIDs/get-dids.service';
import { Did, IDid } from 'src/app/services/models/dids.model';
import { TranslocoModule } from '@jsverse/transloco';
import { SubmitOrderService } from '../../services/submit-order/submit-order.service';
import { PackageInfoService } from '../../services/package-info/package-info.service';
import { IPackageInfo, IPackageInfoRoot } from 'src/app/services/models/package-info.model';
import { SlidersPipe } from 'src/app/pipes/sliders.pipe';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';
import { SpinnerSmallComponent } from 'src/app/core/shared/loading/spinner-small/spinner-small.component';

@Component({
  selector: 'app-choose-number-page',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NeedMoreInfoFormComponent,
    TranslocoModule,
    SlidersPipe,
    FlagsPipe,
    SpinnerSmallComponent
  ],
  templateUrl: './choose-number-page.component.html',
  styleUrl: './choose-number-page.component.scss',
})
export class ChooseNumberPageComponent {
  showNumbers: boolean = false;
  vNumberCountryName = this.route.snapshot.params['vNumCountry'];
  packageCountryName = this.route.snapshot.params['packageCountry'];
  virtualNumberCountryID: number = this.route.snapshot.params['countryID'];
  packageID: string = this.route.snapshot.params['packageID'];
  virtualNumbers: IDid[] = [];
  packageInfo: IPackageInfo;
  monthlyPrice: number | null = null;
  priceInteger: string | null = null;
  priceDecimal: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private getDIDsService: GetDIDsService,
    private submitOrderService: SubmitOrderService,
    private packageInfoService: PackageInfoService
  ) {}

  onChooseVNumber(didData: Did) {
    this.submitOrderService.setOrderData({ didData: didData });
    this.router.navigate(['personal-information', this.packageCountryName, this.packageID]);
  }

  ngOnInit(): void {
    this.getAvailableDIDs(this.virtualNumberCountryID);
    this.getPackageInfo();
  }

  async getAvailableDIDs(countryID: number) {
    try {
      const res = await this.getDIDsService.getAvailableDIDs<IDid[]>(countryID);
      this.virtualNumbers = res.result;
    } catch (error) {}
  }

  async getPackageInfo() {
    try {
      const res =
        await this.packageInfoService.getPackageInfo<IPackageInfoRoot>(
          this.packageID
        );

      if (res.response_code === 200) {
        this.packageInfo = res.response_list.elements[0];
        this.monthlyPrice = this.packageInfo.MonthlyPrice;
        const priceParts = this.monthlyPrice.toFixed(2).split('.');
        this.priceInteger = priceParts[0];
        this.priceDecimal = priceParts[1];
      }
    } catch (error) {
      console.log(error);
    }
  }
}
