<main class="flex flex-col items-center pb-16 px-5">
  <section class="flex justify-center">
    <div class="flex flex-col w-fit py-12">
      <img src="assets/images/icons/personalInfo/shape.svg" alt="" class="w-20">
      <h2 class="text-center px-12 py-4 font-semibold" [innerHTML]="'about-vn.title' | transloco"></h2>
      <img src="assets/images/icons/personalInfo/shape2.svg" alt="" class="w-32 ml-auto">
    </div>
  </section>

  <section class="flex flex-col md:flex-row items-center gap-6 max-w-[37rem]">
    <article class="md:text-end my-auto">
      <h3 class="pb-6" transloco="about-vn.our-mission-title"></h3>
      <p class="text-grey" transloco="about-vn.our-mission-description"></p>
    </article>
    <img src="assets/images/icons/testimonial_1vnumber.svg" alt="" class="w-40 lg:w-60 p-4">
  </section>

  <section class="max-w-[45rem]">
    <div class="flex pb-6">
      <img src="assets/images/icons/smsvnumber.svg" alt="" class="w-10">
      <h3 class="px-3" transloco="about-vn.who-we-are-title"></h3>
    </div>
    <p class="text-grey" [innerHTML]="'about-vn.who-we-are-description' | transloco"></p>
  </section>

  <section class="max-w-[45rem] pt-8">
    <div class="flex pb-6">
      <img src="assets/images/icons/sms_1vnumber.svg" alt="" class="w-10">
      <h3 class="px-3" transloco="about-vn.meet-our-team-title"></h3>
    </div>
    <p class="text-grey" [innerHTML]="'about-vn.meet-our-team-description' | transloco"></p>
  </section>
</main>