import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-friend-form',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoModule],
  templateUrl: './friend-form.component.html',
  styleUrl: './friend-form.component.scss'
})
export class FriendFormComponent {
  @Input() formGroup!: FormGroup;
  @Input() formSubmitted: boolean = false;
  @Input() formNumber!: number;
  
  @ViewChild('phoneInput') phoneInput!: ElementRef;

  friendForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.friendForm = this.fb.group({
      firstName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required], [this.phoneNumberValidator.bind(this)]],
      dialCode: ['']
    });
  }

  ngOnInit() {
    // If a formGroup is provided as input, use it. Otherwise, use the locally created one.
    this.formGroup = this.formGroup || this.friendForm;
  }

  ngAfterViewInit() {
    const inputElement = this.phoneInput.nativeElement;
    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        initialCountry: 'US',
        separateDialCode: true,
        formatOnDisplay: true,
        strictMode: true,
        utilsScript:
          'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
      });

      inputElement.addEventListener('input', () => {
        const phoneNumber = iti.getNumber();
        const dialCode = iti.getSelectedCountryData().dialCode;
        const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();

        this.formGroup.get('phone').setValue(nationalNumber);
        this.formGroup.get('dialCode').setValue(dialCode);
        this.formGroup.get('phone').updateValueAndValidity();
      });
    }
  }

  phoneNumberValidator(
    control: FormControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve) => {
      const cleanedNumber = control.value;

      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement;
        const iti = intlTelInput.getInstance(input);

        if (iti && iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }
}
