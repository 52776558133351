import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { CreditCardComponent } from '@app/core/shared/profile/credit-card/credit-card.component';
import { AboutPageComponent } from "../../../../pages/about-page/about-page.component";

@Component({
  selector: 'app-add-credit',
  standalone: true,
  imports: [CreditCardComponent, TranslocoModule, FormsModule, AboutPageComponent],
  templateUrl: './add-credit.component.html',
  styleUrl: './add-credit.component.scss'
})
export class AddCreditComponent {
  customAmount: number | null = null;

  selectAmount(amount: number): void {
    this.customAmount = amount;
  }

  addCredit(): void {
    const amountToAdd = this.customAmount || 0;
    console.log(`Adding credit: $${amountToAdd}`);
    // Implement the logic to add credit here
  }
}
