import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from '../models/base/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  getAllSavedCreditCards<T>() {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetPaymentMethods}`)
    const data = lastValueFrom(res)
    return data
  }

  getCreditCardById<T>(cardId: number) {
    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetPaymentMethods}/${cardId}`)
    const data = lastValueFrom(res)
    return data
  }

  editSavedCreditCard<T>(submitData: any) {
    const res = this.http.put<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetPaymentMethods}`, submitData)
    const data = lastValueFrom(res)
    return data
  }

  deleteCreditCardById<T>(cardId: number) {
    let params = new HttpParams()
    .set('id', cardId)

    const res = this.http.delete<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetPaymentMethods}`, { params: params})
    const data = lastValueFrom(res)
    return data
  }

  createCreditCard<T>(submitData: any) {
  const res = this.http.post<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetPaymentMethods}`, submitData)    
  const data = lastValueFrom(res)
  return data
  }
}
