import {
  Component,
  computed,
  inject,
  Signal,
  signal,
  ViewChild,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { CouponCodeComponent } from 'src/app/core/shared/coupon-code/coupon-code.component';
import { PaymentFormComponent } from 'src/app/core/shared/payment/payment-form/payment-form.component';
import { BillingFormComponent } from 'src/app/core/shared/payment/billing-form/billing-form.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { FormGroup } from '@angular/forms';
import { InfoPopUpComponent } from 'src/app/core/shared/pop-up/info-pop-up/info-pop-up.component';
import { CouponCodeService } from 'src/app/services/coupon-code/coupon-code.service';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { PackagePriceService } from '@app/services/package-price/package-price.service';
import { SpinnerComponent } from 'src/app/core/shared/loading/spinner/spinner.component';
import { SquaresPipe } from 'src/app/pipes/squares.pipe';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';
import { RecaptchaService } from 'src/app/services/recatpcha/recaptcha.service';
import { GetDIDsService } from '../../services/getDIDs/get-dids.service';
import { Did, IDid } from 'src/app/services/models/dids.model';
import { AuthService } from '@app/services/auth/auth.service';
import { CreditCardComponent } from '@app/core/shared/profile/credit-card/credit-card.component';

@Component({
  selector: 'app-payment-page',
  standalone: true,
  imports: [
    CommonModule,
    CouponCodeComponent,
    CreditCardComponent,
    PaymentFormComponent,
    BillingFormComponent,
    RouterModule,
    InfoPopUpComponent,
    SpinnerComponent,
    TranslocoModule,
    SquaresPipe,
    FlagsPipe,
  ],
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent {
  authService = inject(AuthService);
  packagePriceService = inject(PackagePriceService);
  translateService = inject(TranslocoService);
  submitOrderService = inject(SubmitOrderService);
  couponCodeService = inject(CouponCodeService);
  recaptchaService = inject(RecaptchaService);
  getDIDsService = inject(GetDIDsService);

  @ViewChild('paymentForm') paymentForm: PaymentFormComponent;
  @ViewChild('billingForm') billingForm: BillingFormComponent;

  paymentId: number = 0;

  showPopUp: boolean = false;
  isLoading: boolean = false;

  popUpMessage: string = '';
  couponCode: string = '';
  currentLang: string;

  couponPrice: number = 0;
  totalPrice: Signal<number> = signal(0);

  packageCountryName: string = this.route.snapshot.params['packageCountry'];
  packageID: number = this.route.snapshot.params['packageID'];
  didID: number = this.route.snapshot.params['didID'];

  packagePrice: any;
  didData: Did = null;
  orderData = this.submitOrderService.getOrderData();

  paymentCardForm = this.submitOrderService.submitForm.get(
    'paymentForm'
  ) as FormGroup;
  billingAddressForm = this.submitOrderService.submitForm.get(
    'billingForm'
  ) as FormGroup;
  couponForm = this.submitOrderService.submitForm.get(
    'couponForm'
  ) as FormGroup;
  countriesForm = this.submitOrderService.submitForm.get(
    'countriesForm'
  ) as FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.currentLang = this.translateService.getActiveLang()
  }

  ngOnInit(): void {
    this.getSelectedDID();
    this.getPackagePrice();
  }

  ngAfterViewInit(): void {}

  onSelectedSavedCard(cardId: number) {
    this.paymentId = cardId;
    console.log(this.paymentId);
  }

  onClosePopUp() {
    this.showPopUp = false;
  }

  async submitOrder() {
    this.paymentForm.onSubmitPayment();
    this.billingForm.onSubmitBilling();
    const token = await this.recaptchaService.executeRecaptcha(
      'vNumberPurchase'
    );

    if (this.paymentCardForm.valid && this.billingAddressForm.valid) {
      const jsonData = {
        PlatformID: 17,
        CreditCardNumber: this.paymentCardForm.get('cardNumber').value,
        CardHolder: this.paymentCardForm.get('cardHolder').value,
        SecurityCode: this.paymentCardForm.get('cardCVV').value,
        ExpirationMonth: this.paymentCardForm.get('cardExpirationMM').value,
        ExpirationYear: this.paymentCardForm.get('cardExpirationYY').value,
        BillCountry: this.billingAddressForm.get('country').value,
        State: this.billingAddressForm.get('state').value,
        City: this.billingAddressForm.get('city').value,
        Zip: this.billingAddressForm.get('zipCode').value,
        Address: this.billingAddressForm.get('address').value,
        Phone: this.orderData.dialCode + this.orderData.phone,
        Email: this.orderData.email,
        PackageID: this.packageID,
        Password: this.orderData.password,
        FirstName: this.orderData.firstName,
        LastName: this.orderData.lastName,
        LanguageISO: this.currentLang,
        IsSaveCard: 1,
        IsPrimary: 1,
        PromoCode: this.couponForm.get('couponCode').value,
        PromoCodeParams: {
          PackageID: this.packageID,
          DIDID: this.didData.didid.toString(),
        },
        DIDID: this.didData.didid.toString(),
        inquiryTicket: this.orderData.inquiryTicket,
        recaptcha: token,
      };

      this.isLoading = true;

      try {
        const res = await this.submitOrderService.submitOrder(jsonData);

        if (res.response_code === 200) {
          this.isLoading = false;
          console.log(res);
          this.router.navigate(['/order']);
        } else if (res.response_code === 999) {
          this.isLoading = false;
          this.showPopUp = true;
          this.popUpMessage = 'server-error';
        } else {
          this.isLoading = false;
          this.showPopUp = true;
          this.popUpMessage = res.response_message;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  onCouponCodeSubmit(event: string) {
    this.couponCode = event;
    if (this.couponCode !== '') {
      this.validateCouponCode();
    }
  }

  async validateCouponCode() {
    const jsonData = {
      PackageID: this.packageID,
      DIDID: this.didData?.didid,
      PromoCode: this.couponForm.get('couponCode').value,
      PromoCodeParams: {
        PackageID: this.packageID,
        DIDID: this.didData?.didid,
      },
    };

    try {
      const res = await this.couponCodeService.validateCouponCode(jsonData);

      if (res.response_code === 200) {
        if (res.discount === 0) {
          this.showPopUp = true;
          this.popUpMessage = 'code-not-valid';
        } else {
          this.totalPrice = computed(() => {
            return (
              this.packagePrice - res.discount
            );
          });
        }

        this.couponPrice = res.discount;
      }
    } catch (error) {
      console.log('Coupon validation Error: ', error);
    }
  }

  async getPackagePrice() {
    try {
      const jsonData = {
        packageId: Number(this.packageID),
        didid: this.didID,
        price: 0,
      };
      const res = await this.packagePriceService.getPackagePrice(jsonData);

      if (res) {
        this.packagePrice = res.price;

        setTimeout(() => {
          this.totalPrice = computed(() => {
            return this.packagePrice;
          });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getSelectedDID() {
    const didID = this.route.snapshot.params['didID'];
    const res = await this.getDIDsService.getSelectedDID<Did>(didID);

    if (res.isSuccessful) {
      this.didData = res.result;
    }
  }

  onRemoveCouponCode() {
    this.couponCode = '';
    this.couponPrice = 0;

    this.totalPrice = computed(() => {
      return this.packagePrice + this.didData?.price;
    });
  }

  goBack(): void {
    this.location.back(); // Navegar a la página anterior
  }
}
