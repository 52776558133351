import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import intlTelInput from 'intl-tel-input';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ContactUsInquiryService } from 'src/app/services/contact-us-inquiry/contact-us-inquiry.service';
import { InfoPopUpComponent } from '../../pop-up/info-pop-up/info-pop-up.component';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [TranslocoModule, ReactiveFormsModule, FormsModule, InfoPopUpComponent],
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  showPopUp: boolean = false;
  popUpMessage: string = '';
  formSubmitted: boolean = false;
  isChecked: boolean = false;
  currentLang: string = '';
  translateService = inject(TranslocoService);
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  
  contactForm = this.formBluider.group({
    name: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(/^[a-zA-Z\s]*$/),
        Validators.pattern(/^[\p{L}\s]*$/u),
        Validators.maxLength(30),
      ]),
    ],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone: ['', Validators.required, this.phoneNumberValidator],
    dialCode: ['']
  });

  constructor(
    private formBluider: FormBuilder,
    private contactService: ContactUsInquiryService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.getActiveLang()
  }

  async onSubmitContactForm() {
    
    let marketId;
    this.formSubmitted = true;
    
    console.log(this.contactForm.value)

    if (this.contactForm.valid && this.isChecked) {
      switch (this.currentLang) {
        case 'en' || 'he':
          marketId = 1; // English/Hebrew market
          break;
        case 'es':
          marketId = 2; // Spanish market
          break;
        case 'pt':
          marketId = 3; // Portuguese market
          break;
        default:
          marketId = 1; // default to English/Hebrew market if language is not detected   
          break;
      }

      const jsonData = {
        languageISO: this.currentLang,
        affiliate: 'SG_D_vNumber_Contact_Page',
        MarketID: marketId,
        CountryCode: '+' + this.contactForm.get('dialCode').value,
        PhoneNumber: this.contactForm.get('phone').value,
        email: this.contactForm.get('email').value,
        name: this.contactForm.get('name').value,
        properties: {},
      };
              
      try {
        const res = await this.contactService.sendContactInquiry(jsonData)
        
        if (res.response_code === 200) {
          this.contactForm.reset();
          this.formSubmitted = false;
          this.popUpMessage = 'contact'
          this.showPopUp = true;
        } else {
          this.popUpMessage = 'server-error'
          this.showPopUp = true;
        }
        
      } catch (error) {
        console.log(error)
        this.popUpMessage = 'server-error'
        this.showPopUp = true;
      }

    }
  }

  checkPermissions(value) {
    this.isChecked = value;
  }

  ngAfterViewInit() {
    const inputElement = this.phoneInput.nativeElement;
    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        initialCountry: 'US',
        separateDialCode: true,
        formatOnDisplay: true,
        strictMode: true,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js",
      }); 
      
      inputElement.addEventListener('input', () => {
        const phoneNumber = iti.getNumber();
        const dialCode = iti.getSelectedCountryData().dialCode;
        const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();

        this.contactForm.get('phone').setValue(nationalNumber);
        this.contactForm.get('dialCode').setValue(dialCode);
        this.contactForm.get('phone').updateValueAndValidity();
      });
    }
  }

  phoneNumberValidator(control: FormControl): Promise<{ [key: string]: boolean } | null> {
    return new Promise(resolve => {
      const cleanedNumber = control.value;
  
      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement; 
        const iti = intlTelInput.getInstance(input);
  
        if (iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }

  onClosePopUp() {
    this.showPopUp = false;
  }
}
