<div class="fixed inset-0 bg-white bg-opacity-70 flex justify-center pt-[5%] z-[1000] transition-opacity duration-500 ease-in-out">
 
  <div class="relative background-pic flex flex-col items-center sm:w-[670px] px-12 py-8 border border-greyDark rounded-2xl shadow-2xl bg-white h-max my-auto mx-8">
    <span (click)="closePopUp()" class="absolute right-2 top-2"><img src="assets/images/icons/close-popup.svg" alt="" class="w-12"></span>
    <section class="flex flex-col items-center">
      <img src="assets/images/logo.webp" alt="small logo" class="w-[5rem] sm:w-[10rem] h-fit py-4">
      <div class="text-center">
        <h2 class="text-2xl sm:text-4xl font-semibold underline decoration-solid decoration-orange" [transloco]="'popup-vn.'+ translate +'.title'"></h2>
        <h4 class="font-semibold text-base sm:text-lg pt-4" [transloco]="'popup-vn.'+ translate +'.subtitle'"></h4>
      </div>
    </section>

    <section class="border-t border-greyLight">
      <p class="text-grey text-sm sm:text-base pt-6 pb-8" [transloco]="'popup-vn.'+ translate +'.message'"></p>
      <div class="flex justify-center">
        <button (click)="closePopUp()"
        class="bg-orange rounded-full py-3 px-14 font-semibold text-white" transloco="popup-vn.back-btn"></button>
      </div>
    </section>
  </div>
</div>  