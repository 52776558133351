
export const ControllerApiList = {
    WebApi: {
      States: "api/Country/GetStatesByContryID",
      GetSimPrice: "api/simorder/GetSimPrices",
      PromoCodeValidation: "api/customer/ValidateCoupon",
      ContactUsInquiry: "api/Inquiry/CreateInquiry",
      Countries: "api/country/GetCountries",
      FindCustomer: "api/customer/FindCustomer",
      CreateInqTicket: "api/Inquiry/CreateInquiry",
      CheckSMSCode: "api/Customer/ValidateCode",
      SubmitOrder: "api/Customer/CreatevalidatedSubscriber",
      GetPackageInfo: "api/Package/PackageInfo",
      ResetPassword: "api/account/PasswordReset",
    },
    HubApi: {
      GetCountries: "api/globalhub/countries",
      VnumberApi: {
        SignIn: "api/vnumber/auth/Login",
        GetAvailableDIDs: "api/vnumber/dids/get-dids",
        GetSelectedDID: "api/vnumber/dids/get-did",
        GetDIDCountries: "api/vnumber/dids/get-countries-with-active-dids",
        GetPackageCountries: "api/vnumber/country/get-countries-packages",
        GetMyVNumbers: "api/vnumber/my-vnumbers",
        GetCallHistory: "api/vnumber/calls/call-history",
        GetBillingHistory: "api/vnumber/billing/billing-history",
        GetPaymentMethods: "api/vnumber/billing/payment-methods/credit-card",
        GetAccountInfo: "api/vnumber/account-information",
        VerifyEmailChange: "api/vnumber/update-customer-email",
        ValidateCustomerEmail: "api/vnumber/validate-customer-email"
      }
    }
}

