import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlagsPipe } from '@app/pipes/flags.pipe';
import { PhoneFormatPipe } from '@app/pipes/phone-format.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-vnumber-details',
  standalone: true,
  imports: [FlagsPipe, NgxChartsModule, PhoneFormatPipe, RouterModule, TranslocoModule],
  templateUrl: './vnumber-details.component.html',
  styleUrl: './vnumber-details.component.scss'
})
export class VnumberDetailsComponent {
  @Input() number: any;

  totalMinutes: number = 500;
  usedMinutes: number = 200;
  remainingMinutes: number = this.totalMinutes - this.usedMinutes;
  chartData: any[];

  constructor() {}

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.number = {
        countryName: 'Uruguay',
        iso2: "UY",
        didid: 41809,
        didNumber: "59899099099",
        mask: "xxx-xx-xxx-xxx",
        prefix: "598",
    }

    this.chartData = [
      {
        name: 'Used',
        value: this.usedMinutes
      },
      {
        name: 'Remaining',
        value: this.remainingMinutes
      }
    ];
  }
}
