<div class="flex flex-col gap-12 py-4 px-5 border-t">
  <!-- FORWARDING SETTINGS -->
  <div class="border-x px-5">
    <h3 class="text-base font-semibold mb-2">Forwarding Settings</h3>
    <p class="text-xs text-grey pb-2 border-b">
      Simplify your connections! Send calls to your local phone and get SMS instantly in your email.
    </p>
    <div class="">
      <!-- SET UP CALLS -->
      <div class="flex flex-wrap gap-4 items-center pt-4">
        <span>Set up Call forwarding:</span>
        <div class="flex flex-1 items-center justify-between gap-2">
          <div class="flex items-center gap-3 py-2 px-4 border rounded min-w-48">
            <img [src]="number.countryName | appFlags" class="w-5">
            <span class="text-sm font-semibold">{{ number.didNumber | phoneFormat:number.mask:number.prefix }}</span>
          </div>
          <button transloco="btn-vn.edit"
            class="px-3 py-1 text-xs border hover:border-orange rounded-md hover:text-orange"></button>
        </div>
      </div>
      <!-- SET UP SMS -->
      <div class="flex flex-wrap gap-4 items-center py-4">
        <span>Set up SMS forwarding:</span>
        <div class="flex flex-1 items-center justify-between gap-2">
          <label for="newEmail" class="hidden">Email</label>
          <input id="email" type="email" [placeholder]="'form.email' | transloco"
            class="block min-h-[auto] w-fit rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
          <button transloco="btn-vn.edit"
            class="px-3 py-1 text-xs border hover:border-orange rounded-md hover:text-orange"></button>
        </div>
      </div>
    </div>
  </div>

  <!-- BALANCE -->
  <div class="border-x px-5">
    <h3 class="text-base font-semibold mb-2">Your balance</h3>
    <div class="flex items-center gap-4">
      <!-- <div class="relative w-24 h-24">
        <svg viewBox="0 0 100 100" class="w-full h-full">
          <circle cx="50" cy="50" r="45" fill="none" stroke="#e6e6e6" stroke-width="10" />
          <circle cx="50" cy="50" r="45" fill="none" stroke="#4CAF50" stroke-width="10" stroke-dasharray="282.7"
            stroke-dashoffset="84.81" transform="rotate(-90 50 50)" />
        </svg>
        <div class="absolute inset-0 flex items-center justify-center flex-col">
          <span class="text-2xl font-bold">200</span>
          <span class="text-xs">/500min</span>
        </div>
      </div> -->
      <div class="w-48 h-48">
        <ngx-charts-pie-chart
          [view]="[200, 200]"
          [results]="chartData"
          [labels]="true"
          [doughnut]="true">
        </ngx-charts-pie-chart>
      </div>
      <div class="">
        <p class="text-sm">You have <span class="font-bold">300 minutes</span> available in your plan to receive calls.
        </p>
        <p class="text-sm mt-2">Need to make outgoing calls?</p>
        <p class="text-sm">Do it easily by adding credit to your account</p>
        <button routerLink="/profile/add-credit" transloco="btn-vn.add-credit" class="mt-2 px-6 py-1 bg-orange text-white font-semibold rounded-xl uppercase hover:scale-105 transition duration-100">ADD CREDIT</button>
      </div>
    </div>
  </div>
</div>