<section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
  <h2 class="text-2xl font-bold mb-6" transloco="profile-vn.account-info.title"></h2>

  <div class="">
    <!-- <h3 class="text-base font-semibold py-8" transloco="profile-vn.account-info.subtitle"></h3> -->


    <!-- EMAIL -->
    <div class="py-4 border-b">
      <label for="email" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.email"></label>
      <div class="flex items-center justify-between">
        @if (!isEmailEditable) {
        <span class="text-grey">{{ userData?.customerEmail }}</span>
        <button type="button" (click)="isEmailEditable = true"
          class="px-3 py-2 text-xs border rounded font-medium hover:text-orange hover:border-orange"
          transloco="btn-vn.edit"></button>
        }@else {
        <form [formGroup]="changeEmailForm" (ngSubmit)="updateEmail()" class="w-full">
          <div class="flex justify-between">
            <div class="flex flex-col gap-2">
              <!-- ENTER NEW MAIL -->
              <div class="">
                <label for="newEmail" class="hidden">New Email</label>
                <input id="newEmail" type="email" formControlName="newEmail"
                  [placeholder]="'form.enter-new-email' | transloco"
                  class="{{changeEmailForm.get('newEmail')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
              </div>
              <!-- CONFIRM NEW EMAIL -->
              <div class="">
                <label for="confirmEmail" class="hidden">Confirm Email</label>
                <input id="confirmEmail" type="email" formControlName="confirmEmail"
                  [placeholder]="'form.re-enter-new-email' | transloco"
                  class="{{changeEmailForm.get('confirmEmail')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
                @if (changeEmailForm.errors?.['emailsMismatch'] && changeEmailForm.get('confirmEmail')?.touched) {
                <small class="text-red" transloco="form.emails-not-match"></small>
                }
              </div>
            </div>

            <div class="flex flex-col gap-1 my-auto">
              <button type="submit"
                class="{{ changeEmailForm.invalid ? 'border-red text-red' : 'border-green text-green hover:scale-105 transition duration-200'}} ml-auto px-3 py-2 text-xs border rounded font-medium"
                transloco="btn-vn.verify"></button>
              <button type="button" (click)="isEmailEditable = false"
                class="px-3 py-2 text-xs border rounded font-medium" transloco="btn-vn.back"></button>
            </div>
          </div>
        </form>
        }
      </div>
    </div>

    <!-- PASSWORD -->
    <div class="py-4 border-b">
      <label for="password" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
        transloco="form.password"></label>
      <div class="flex flex-1 items-center justify-between w-full">
        @if (!isPasswordEditable) {
        <span class="text-grey">*************</span>
        <button type="button" (click)="isPasswordEditable = true"
          class="px-3 py-2 text-xs border rounded font-medium hover:text-orange hover:border-orange"
          transloco="btn-vn.edit"></button>
        }@else {
        <form [formGroup]="changePasswordForm" (ngSubmit)="updatePassword()" class="w-full">
          <div class="flex justify-between gap-2">
            <div class="flex flex-col sm:flex-row gap-6 sm:gap-2">
              <!-- ENTER CURRENT PASSWORD -->
              <div class="form-group">
                <label for="currentPassword" class="hidden">Contraseña Actual</label>
                <input id="currentPassword" type="password" formControlName="currentPassword"
                  [placeholder]="'form.enter-current-password' | transloco"
                  class="{{changePasswordForm.get('currentPassword')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
              </div>
              <div class="flex flex-col gap-2">
                <!-- ENTER NEW PASSWORD -->
                <div class="form-group">
                  <label for="newPassword" class="hidden">Nueva Contraseña</label>
                  <input id="newPassword" type="password" formControlName="newPassword"
                    [placeholder]="'form.enter-new-password' | transloco"
                    class="{{changePasswordForm.get('newPassword')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
                </div>
                <!-- CONFIRM NEW PASSWORD -->
                <div class="form-group">
                  <label for="confirmPassword" class="hidden">Repetir Contraseña</label>
                  <input id="confirmPassword" type="password" formControlName="confirmPassword"
                    [placeholder]="'form.re-enter-new-password' | transloco"
                    class="{{changePasswordForm.get('confirmPassword')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
  
                  @if (changePasswordForm.invalid) {
                  <div class="flex flex-col text-red">
                    <small *ngIf="changePasswordForm.get('newPassword')?.errors?.['minlength'] && formSubmitted"
                      transloco="form.password-character"></small>
                    <small *ngIf="changePasswordForm.get('newPassword')?.errors?.['noUpperCase'] && formSubmitted"
                      transloco="form.password-uppercase"></small>
                    <small *ngIf="changePasswordForm.get('newPassword')?.errors?.['noNumber'] && formSubmitted"
                      transloco="form.password-number"></small>
                    <small *ngIf="changePasswordForm.get('newPassword')?.errors?.['noLetter'] && formSubmitted"
                      transloco="form.password-uppercase"></small>
                    <small
                      *ngIf="changePasswordForm.errors?.['passwordMismatch'] && changePasswordForm.get('confirmPassword')?.touched"
                      transloco="form.passwords-not-match"></small>
                  </div>
                  }
                </div>
              </div>
            </div>

            <div class="flex flex-col gap-1 my-auto">
              <button type="submit"
                class="{{ changePasswordForm.invalid ? 'border-red text-red' : 'border-green text-green hover:scale-105 transition duration-200'}} ml-auto px-3 py-2 text-xs border rounded font-medium"
                transloco="btn-vn.save"></button>
              <button type="button" (click)="isPasswordEditable = false"
                class="px-3 py-2 text-xs border rounded font-medium" transloco="btn-vn.back"></button>
            </div>
          </div>
        </form>
        }
      </div>
    </div>

    <!-- FIRST NAME, LAST NAME & LANGUAGE -->
    <form [formGroup]="profileForm" (ngSubmit)="updateFullNameAndLanguage()">
      <div class="flex flex-col sm:flex-row gap-2 py-6 border-b">
        <!-- FIRST NAME -->
        <div class="w-full">
          <label for="firstName" class="block mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
            transloco="form.first-name"></label>
          <input type="text" id="firstName" formControlName="firstName" class="p-2 border w-full rounded text-grey">
        </div>
        <!-- LAST NAME -->
        <div class="w-full">
          <label for="lastName" class="block mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
            transloco="form.last-name"></label>
          <input type="text" id="lastName" formControlName="lastName" class="p-2 border w-full rounded text-grey">
        </div>
      </div>
      <!-- LANGUAGE -->
      <div class="py-6">
        <label for="language" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
          transloco="form.language"></label>
        <select id="language" formControlName="language"
          class="block w-full sm:w-1/2 p-2 border rounded text-grey">
          @for (lang of availableLangs; track lang) {
          <option [value]="lang.id">
            <span>{{ lang.label }}</span>
          </option>
          }
        </select>
      </div>

      <!-- TIME ZONE -->
      <!-- <div class="mb-6">
        <label for="timezone" class="block text-sm font-medium text-gray-700">Zona horaria</label>
        <select id="timezone" formControlName="timezone"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
          @for (tz of timezones; track tz) {
            <option [value]="tz">{{ tz }}</option>
          }
        </select>
      </div> -->

      <!-- SAVE CHANGES BUTTON -->
      @if (profileForm.get('language').dirty || profileForm.get('firstName').dirty || profileForm.get('lastName').dirty)
      {
      <button type="submit" transloco="btn-vn.save-changes"
        class="w-full mt-4 px-4 py-2 bg-orange text-white rounded-xl hover:scale-105 transition duration-200"></button>
      }
    </form>

    <!-- DELETE ACCOUNT -->
    <div class="mt-8 pt-6 border-t">
      <!-- <h3 class="text-lg font-medium text-gray-900" transloco="profile-vn.account-info.delete-account.title"></h3> -->
      <!-- <p class="mt-1 text-sm text-gray-600" transloco="profile-vn.account-info.delete-account.description"></p> -->
      <button (click)="onDeleteAccount()" class="mt-4 py-2 text-sm text-red underline"
        transloco="btn-vn.delete-account"></button>
    </div>
  </div>

  @if (showDeleteAccountModal) {
    <app-delete-account-modal (closeModalEvent)="onCloseModal($event)"></app-delete-account-modal>
  }
</section>


@if (isLoading) {
  <app-spinner></app-spinner>
}

@if(showPopUp) {
  <app-info-pop-up
    [showPopUp]="showPopUp" 
    (closePopUpEvent)="onClosePopUp()" 
    [translate]="popUpMessage">
  </app-info-pop-up>
}