import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ApiResponse } from '../models/base/ApiResponse';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessNumbersService {

  constructor(private http: HttpClient, private settings: SettingsService) { }

  getAccessNumbers<T>(countryId: number, stateId: number) {

    let params = new HttpParams()
      .set("countryId", countryId)
      .set("stateId", stateId)

    const res = this.http.get<ApiResponse<T>>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.GetAccessNumbers}`, {params: params});
    const data = lastValueFrom(res);
    return data
  }
}
