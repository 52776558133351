<section class="rounded-xl shadow-full border border-greyLight pb-9">
  <h2 class="rounded-xl sticky top-0 bg-white text-2xl font-bold py-9 px-6 md:px-16"
    transloco="profile-vn.my-vnumbers.call-history.title">
  </h2>

  <article class="scrollbar-sec w-full overflow-y-auto max-h-[600px] px-6 md:px-16">
    @if (previousCalls?.length !== 0) {
      <!-- RECENT CALLS -->
      <app-call-history-list title="recent" [calls]="recentCalls"></app-call-history-list>
      <!-- PREVIOUS CALLS -->
      <app-call-history-list title="previous" [calls]="previousCalls"></app-call-history-list>
    }@else {
      <div class="flex items-center justify-center text-sm font-medium border py-2 px-6 rounded-xl w-fit mx-auto" transloco="profile-vn.my-vnumbers.call-history.no-calls"></div>
    }
  </article>
</section>