import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NeedMoreInfoFormComponent } from 'src/app/core/shared/choose-your-number/need-more-info-form/need-more-info-form.component';
import { GetDIDsService } from '../../services/getDIDs/get-dids.service';
import { Did, IDid } from 'src/app/services/models/dids.model';
import { TranslocoModule } from '@jsverse/transloco';
import { SubmitOrderService } from '../../services/submit-order/submit-order.service';
import { PackagePriceService } from '../../services/package-price/package-price.service';
import { IPackagePrice } from '@app/services/models/package-price.model';
import { SlidersPipe } from 'src/app/pipes/sliders.pipe';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';
import { SpinnerSmallComponent } from 'src/app/core/shared/loading/spinner-small/spinner-small.component';

@Component({
  selector: 'app-choose-number-page',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NeedMoreInfoFormComponent,
    TranslocoModule,
    SlidersPipe,
    FlagsPipe,
    SpinnerSmallComponent,
  ],
  templateUrl: './choose-number-page.component.html',
  styleUrl: './choose-number-page.component.scss',
})
export class ChooseNumberPageComponent {
  showNumbers: boolean = false;
  vNumberCountryName = this.route.snapshot.params['vNumCountry'];
  packageCountryName = this.route.snapshot.params['packageCountry'];
  virtualNumberCountryID: number = this.route.snapshot.params['countryID'];
  packageID: string = this.route.snapshot.params['packageID'];
  virtualNumbers: IDid[] = [];
  packagePrice: any;
  monthlyPrice: number | null = null;
  priceInteger: string | null = null;
  priceDecimal: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private getDIDsService: GetDIDsService,
    private submitOrderService: SubmitOrderService,
    private packagePriceService: PackagePriceService
  ) {}

  ngOnInit(): void {
    this.getAvailableDIDs(this.virtualNumberCountryID);
    this.getPackagePrice();
  }

  onChooseVNumber(didData: Did) {
    this.submitOrderService.setOrderData({ didData: didData });
    this.router.navigate([
      'personal-information',
      this.packageCountryName,
      this.packageID,
      didData.didid
    ]);
  }

  checkRates() {
    this.router.navigate(['rates-per-country', this.packageID]);
  }

  async getAvailableDIDs(countryID: number) {
    try {
      const res = await this.getDIDsService.getAvailableDIDs<IDid[]>(countryID);
      this.virtualNumbers = res.result;
    } catch (error) {}
  }

  async getPackagePrice() {
    try {
      const jsonData = {
        packageId: Number(this.packageID),
        didid: 0,
        price: 0,
      };
      const res = await this.packagePriceService.getPackagePrice(jsonData);

      if (res) {
        this.packagePrice = res.price
        const priceParts = this.packagePrice.toFixed(2).split('.');
        this.priceInteger = priceParts[0];
        this.priceDecimal = priceParts[1];
      }
    } catch (error) {
      console.log(error);
    }
  }
}
