import { Component, inject } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { AccessNumbersComponent } from '../access-numbers/access-numbers.component';
import { InfoPopUpComponent } from '../../../pop-up/info-pop-up/info-pop-up.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ContactUsInquiryService } from '@app/services/contact-us-inquiry/contact-us-inquiry.service';

@Component({
  selector: 'app-support',
  standalone: true,
  imports: [
    AccessNumbersComponent,
    InfoPopUpComponent,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  templateUrl: './support.component.html',
  styleUrl: './support.component.scss',
})
export class SupportComponent {
  contactService = inject(ContactUsInquiryService);
  translateService = inject(TranslocoService);

  subjectForm = this.fb.group({
    subject: ['', Validators.required],
    message: ['', Validators.required],
  });

  showPopUp: boolean = false;
  popUpMessage: string = '';
  formSubmitted: boolean = false;
  currentLang: string;

  constructor(private fb: FormBuilder) {
    this.currentLang = this.translateService.getActiveLang();
  }

  ngOnInit(): void {}

  async onSubmitSubjectForm() {
    this.formSubmitted = true;

    if (this.subjectForm.valid) {
      const jsonData = {
        affiliate: 'MobSite_Support',
        languageISO: this.currentLang,
        properties: {
          Message: this.subjectForm.get('message').value,
          Subject: this.subjectForm.get('subject').value,
        },
      };

      try {
        const res = await this.contactService.sendContactInquiry(jsonData)

        // if (res.isSuccefull && res.result) {
        //   this.showPopUp = true
        // }
      } catch (error) {}
    }
  }

  onClosePopUp() {
    this.showPopUp = false
  }
}
