import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CountriesService } from '@app/services/countries/countries.service';
import { ICountries } from '@app/services/models/countries.model';
import { IState } from '@app/services/models/states.model';
import { StatesService } from '@app/services/states/states.service';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-access-numbers',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoModule],
  templateUrl: './access-numbers.component.html',
  styleUrl: './access-numbers.component.scss',
})
export class AccessNumbersComponent {
  accessNumbersForm = this.fb.group({
    country: ['', Validators.required],
    state: ['', Validators.required],
    areaCode: ['', Validators.required],
  });

  countriesService = inject(CountriesService);
  statesService = inject(StatesService);
  translocoService = inject(TranslocoService);

  countries: ICountries[] = [];
  states: IState[] = [];
  selectedCountryId: string = '';
  selectedState: string = '';
  currentLang: string = '';
  formSubmitted: boolean = false;
  numbers: string[] = [];

  constructor(private fb: FormBuilder) {
    this.currentLang = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.getCountries()
    this.numbers = ["(1) 303-586-7177", "(1) 970-987-5471"]
  }

  onSubmitAccessNumbers() {

  }

  onCountryChange(event: Event) {
    const countryCode = Number((event.target as HTMLSelectElement).value);
    this.getStates(countryCode);
  }

  async getCountries() {
    const res = await this.countriesService.getCountrySelection<ICountries[]>();

    if (res.isError) {
      console.error('ERROR: ', res.errorMessage);
      return;
    }

    if (res.isSuccessful) {
      this.countries = res.result;

      if (this.countries.length > 0) {
        this.selectedCountryId = this.currentLang === 'he' ? '101' : '218';
        this.accessNumbersForm.get('country')?.setValue(this.selectedCountryId);
        this.getStates(Number(this.selectedCountryId));
      }
    }
  }

  async getStates(countryId: number) {
    const res = await this.statesService.getStates<IState[]>(countryId);

    if (res.isSuccessful) {
      this.states = res.result;
    }
  }
}
