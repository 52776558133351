<div class="py-10">
  <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()" class="p-6 smd:p-12 w-fit rounded-3xl shadow-full">
    <div class="flex flex-col lg:flex-row gap-8 justify-evenly py-4 lg:w-fit">

      <!--Name input-->
      <div class=" w-full">
        <label for="name" class="max-w-[90%] font-medium" transloco="form.name"></label>
        <input type="text" formControlName="name" [placeholder]="'form.name' | transloco"
          class="{{contactForm.get('name')?.invalid && formSubmitted ? 'border-danger' : 'border-greyDark'}} w-full rounded border  bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey peer-invalid:border-red"
          id="fullName" />
      </div>

      <!--Email input-->
      <div class=" w-full">
        <label for="email" class="max-w-[90%] font-medium" transloco="form.email"></label>
        <input type="email" formControlName="email" id="email" [placeholder]="'form.email' | transloco"
          class="{{contactForm.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey peer-invalid:border-red" />
      </div>

      <!--Phone input-->
      <div class="flex flex-col w-full">
        <label for="phoneInput" class="max-w-[90%] font-medium" transloco="form.phone"></label>
        <input #phoneInput type="tel" id="phoneInput" formControlName="phone"
          class="{{contactForm.get('phone')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} w-full rounded border border-greyDark bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey peer-invalid:border-red" />
      </div>
    </div>

    <div class="mb-4 flex items-center justify-between max-w-[830px]">
      <div class="block pl-6">
        <input
          class="{{ formSubmitted && !isChecked ? 'border-danger': 'border-greyDark'}} relative float-left -ml-[1.5rem] mr-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded border-[0.125rem] border-solid  outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
          type="checkbox" value="" id="checkPermissions" (change)="checkPermissions(!isChecked)"
          [checked]="isChecked" />
        <label class="inline-block lg:pl-4 hover:cursor-pointer text-sm text-grey" for="checkPermissions" transloco="form.check-permission"></label>
      </div>
    </div>

    <div class="flex justify-center py-4">
      <button type="submit"
        class="{{ isChecked && contactForm.valid ? 'transition delay-75 duration-200 hover:scale-110' : ''}} flex bg-orange items-center text-white font-semibold uppercase rounded-full pl-12 pr-8 py-3 w-max">
        <span transloco="btn-vn.submit"></span>
        <img src="assets/images/icons/arrow-icon.svg" alt="white arrow"
          class="mx-2 pb-1 w-[2rem] h-fit animate-bounce-right">
      </button>
    </div>


  </form>

  @if (showPopUp) {
    <app-info-pop-up
      [showPopUp]="showPopUp" 
      (closePopUpEvent)="onClosePopUp()" 
      [translate]="popUpMessage">
    </app-info-pop-up>
  }
  
</div>