<main class="flex flex-col items-center px-5 py-16">

  <img src="assets/images/logo.webp" alt="logo vnumber" class="w-72">

  <p>Email verification</p>

  <div class="flex flex-col items-center px-24 py-8 border rounded-xl mt-24 mb-32">
    @if (valid) {
      <img src="assets/images/icons/success.svg" alt="success icon" class="w-12">
      <span>Email validation successfull</span>
      <span>You will be redirected to login in {{ timer }} seconds</span>
    }
    
    @if (!valid && errorCode !== "") {
      <img src="assets/images/icons/error.svg" alt="error icon" class="w-12">
      <span [transloco]="'validate-email.error-' + errorCode"></span>
    }
  </div>
</main>