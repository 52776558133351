<div class="flex flex-col gap-8">
  <section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
    <h2 class="text-2xl font-bold leading-none" transloco="profile-vn.support.title"></h2>
    <div class="flex items-center pb-16">
      <span class="text-sm font-medium" transloco="profile-vn.support.subtitle"></span>
      <img src="assets/images/icons/arrow-orange.svg" alt="white arrow" class="w-8 h-8">
    </div>



      <article class="flex">
        <div class="hidden lg:flex py-12">
          <img src="assets/images/call.png" alt="">
        </div>
        <form [formGroup]="subjectForm" (ngSubmit)="onSubmitSubjectForm()"
          class="p-10 rounded-3xl shadow-full w-full lg:max-w-[380px]">
          <div class="flex flex-col">
            <!-- SUBJECT -->
            <div class="mb-4">
              <label for="subject" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm text-grey"
                transloco="form.subject"></label>
              <select type="text" formControlName="subject"
                class="{{subjectForm.get('subject')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
                id="subject">
                <option value="Subject" selected disabled transloco="form.subject"></option>
                <option value="Technical Support" transloco="profile-vn.support.subject.tech-support"></option>
                <option value="Billing Support" transloco="profile-vn.support.subject.bill-support"></option>
                <option value="Plans Info" transloco="profile-vn.support.subject.plan-info"></option>
                <option value="General Question" transloco="profile-vn.support.subject.general-question"></option>
                <option value="Other" transloco="profile-vn.support.subject.other"></option>
              </select>

            </div>
            <!-- SHORT MESSAGE -->
            <div class="mb-6">
              <label for="message" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm text-grey"
                transloco="form.short-message"></label>
              <textarea formControlName="message"
                class="block min-h-[auto] w-full rounded border border-greyDark bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
                id="message" rows="3"></textarea>
            </div>

          </div>

          <div class="flex justify-center">
            <button type="submit" transloco="btn-vn.submit"
              class="{{ subjectForm.valid ? 'transition delay-75 duration-200 hover:scale-110' : '' }} flex items-center text-orange border-2 border-orange font-semibold uppercase rounded-full px-16 py-3 w-max">
            </button>
          </div>
        </form>
      </article>

      <article class="flex flex-col lg:flex-row items-center gap-6 lg:gap-20 py-10 text-grey text-xl font-medium">
        <!-- CALL US -->
        <div class="flex flex-col items-center">
          <img src="assets/images/icons/contact_1vnumber.svg" alt="" class="w-12 p-3 rounded-full shadow-full">
          <p class=" py-4">
            <span transloco="choose-number.need-more-info.call-us"></span>
            <strong>1-877-993-9770</strong>
          </p>
        </div>
        <!-- WHATSAPP -->
        <div class="flex flex-col items-center">
          <img src="assets/images/icons/contactvnumber.svg" alt="" class="w-12 p-3 rounded-full shadow-full">
          <p class="py-4" [innerHTML]="'choose-number.need-more-info.whatsapp' | transloco"></p>
        </div>
      </article>

    @if (showPopUp) {
    <app-info-pop-up [showPopUp]="showPopUp" (closePopUpEvent)="onClosePopUp()" [translate]="popUpMessage">
    </app-info-pop-up>
    }

  </section>

  <app-access-numbers></app-access-numbers>
</div>