import { Component, inject } from '@angular/core';
import { PhoneFormatPipe } from '@app/pipes/phone-format.pipe';
import { CallHistoryService } from '@app/services/call-history/call-history.service';
import { ICallHistory } from '@app/services/models/call-history.model';
import { TranslocoModule } from '@jsverse/transloco';
import { RandomColorPipe } from 'src/app/pipes/random-color.pipe';

@Component({
  selector: 'app-call-history',
  standalone: true,
  imports: [TranslocoModule, PhoneFormatPipe, RandomColorPipe],
  templateUrl: './call-history.component.html',
  styleUrl: './call-history.component.scss',
})
export class CallHistoryComponent {
  callHistoryService = inject(CallHistoryService);
  calls: ICallHistory[] = [];

  constructor() {}

  ngOnInit(): void {
    this.getCallHistory();
  }

  async getCallHistory() {
    try {
      const res = await this.callHistoryService.getCallHistory<ICallHistory[]>()

      if (res.isSuccessful && res.result) {
        this.calls = res.result
      }
    } catch (error) {
      console.log(error)
    }
  }
}
