@if (!isEditing || (isEditing && editingCard !== null)) {
<div
	class="fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center z-50 transition-opacity duration-500 ease-in-out scrollbar-sec overflow-y-auto">
	<span (click)="closeCCModal()"
		class="absolute right-2 top-2 mt-16 sm:mt-20 cursor-pointer active:scale-90 bg-white rounded-full z-10">
		<img src="assets/images/icons/close-popup.svg" alt="" class="w-8 sm:w-12"></span>

	<div id="cardContainer" class="relative flex flex-col items-center px-5 sm:px-0 w-full">
		<!-- TITLE -->
		<div class="payment-title">
			<h4 class="text-3xl text-center font-medium text-white mt-12 pt-16"
				[transloco]="isEditing ? 'profile-vn.billing.payment-methods.edit-payment-method' : 'profile-vn.billing.payment-methods.add-payment-method'">
			</h4>
		</div>
		<!-- CARD IMAGE -->
		<div class="relative z-40 container preload">
			<div class="creditcard" [class]="{'flipped': flipped}" (click)="flipCard()">
				<div class="front">
					<div id="ccsingle">
						@if (cardType !== "") {
						<img [src]="'assets/images/icons/payment/' + cardType + '-icon.svg'" alt="card logo" class="w-16">
						}
					</div>
					<svg direction="ltr" version="1.1" id="cardfront" xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 471"
						style="enable-background:new 0 0 750 471;" xml:space="preserve">
						<g id="Front">
							<g id="CardBackground">
								<g id="Page-1_1_">
									<g id="amex_1_">
										<path id="Rectangle-1_1_" class="lightcolor" [class]="{'lime': cardType === 'visa',
										'yellow': cardType === 'mastercard',
										'purple': cardType === 'discover',
										'orange': cardType === 'diners',
										'green': cardType === 'american-express',
										'grey': cardType === '' }" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
												C0,17.9,17.9,0,40,0z" />
									</g>
								</g>
								<path class="darkcolor " [class]="{'limedark': cardType === 'visa',
								'yellowdark': cardType === 'mastercard',
								'purpledark': cardType === 'discover',
								'orangedark': cardType === 'diners',
								'greendark': cardType === 'american-express',
								'greydark': cardType === ''}"
									d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
							</g>
							<text transform="matrix(1 0 0 1 60.106 295.0121)" id="svgnumber" class="st2 st3 st4">
								{{ creditCardForm.get('cardNumber').value }}</text>
							<text transform="matrix(1 0 0 1 54.1064 428.1723)" id="svgname" class="st2 st5 st6">
								{{ creditCardForm.get('cardHolder').value }}</text>
							<text transform="matrix(1 0 0 1 54.1074 389.8793)" class="st7 st5 st8">cardholder name</text>
							<text transform="matrix(1 0 0 1 479.7754 388.8793)" class="st7 st5 st8">expiration</text>
							<text transform="matrix(1 0 0 1 65.1054 241.5)" class="st7 st5 st8">card number</text>
							<g>
								<text transform="matrix(1 0 0 1 574.4219 433.8095)" id="svgexpire" class="st2 st5 st9">
									{{ creditCardForm.get('cardExpirationMM').value + '/' +
									creditCardForm.get('cardExpirationYY').value.slice(-2) }}</text>
								<text transform="matrix(1 0 0 1 479.3848 417.0097)" class="st2 st10 st11">VALID</text>
								<text transform="matrix(1 0 0 1 479.3848 435.6762)" class="st2 st10 st11">THRU</text>
								<polygon class="st2" points="554.5,421 540.4,414.2 540.4,427.9 		" />
							</g>
							<g id="cchip">
								<g>
									<path class="st2" d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
										c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
								</g>
								<g>
									<g>
										<rect x="82" y="70" class="st12" width="1.5" height="60" />
									</g>
									<g>
										<rect x="167.4" y="70" class="st12" width="1.5" height="60" />
									</g>
									<g>
										<path class="st12" d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
												c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
												C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
												c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
												c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
									</g>
									<g>
										<rect x="82.8" y="82.1" class="st12" width="25.8" height="1.5" />
									</g>
									<g>
										<rect x="82.8" y="117.9" class="st12" width="26.1" height="1.5" />
									</g>
									<g>
										<rect x="142.4" y="82.1" class="st12" width="25.8" height="1.5" />
									</g>
									<g>
										<rect x="142" y="117.9" class="st12" width="26.2" height="1.5" />
									</g>
								</g>
							</g>
						</g>
						<g id="Back">
						</g>
					</svg>
				</div>
				<div class="back flipped">
					<svg direction="ltr" version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 471"
						style="enable-background:new 0 0 750 471;" xml:space="preserve">
						<g id="Front">
							<line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
						</g>
						<g id="Back">
							<g id="Page-1_2_">
								<g id="amex_2_">
									<path id="Rectangle-1_2_" class="darkcolor" [class]="{'limedark': cardType === 'visa',
									'yellowdark': cardType === 'mastercard',
									'purpledark': cardType === 'discover',
									'orangedark': cardType === 'diners',
									'greendark': cardType === 'american-express',
									'greydark': cardType === ''}" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
										C0,17.9,17.9,0,40,0z" />
								</g>
							</g>
							<rect y="61.6" class="st2" width="750" height="78" />
							<g>
								<path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
								C707.1,246.4,704.4,249.1,701.1,249.1z" />
								<rect x="42.9" y="198.6" class="st4" width="664.1" height="10.5" />
								<rect x="42.9" y="224.5" class="st4" width="664.1" height="10.5" />
								<path class="st5"
									d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
							</g>
							<text transform="matrix(1 0 0 1 621.999 227.2734)" id="svgsecurity" class="st6 st7">
								{{ creditCardForm.get('cardCVV').value }}</text>
							<g class="st8">
								<text transform="matrix(1 0 0 1 518.083 280.0879)" class="st9 st6 st10">security
									code</text>
							</g>
							<rect x="58.1" y="378.6" class="st11" width="375.5" height="13.5" />
							<rect x="58.1" y="405.6" class="st11" width="421.7" height="13.5" />
							<text transform="matrix(1 0 0 1 59.5073 228.6099)" id="svgnameback" class="st12 st13">{{
								creditCardForm.get('cardHolder').value }}</text>
						</g>
					</svg>
				</div>
			</div>
		</div>
		<!-- CARD FORM -->
		<article id="creditModal"
			class="absolute top-80 mx-5 sm:mx-0 bg-white px-5 sm:px-8 py-8 mt-8 rounded-2xl max-w-[460px]">
			@if (!showAddressForm) {
			<form [formGroup]="creditCardForm" (ngSubmit)="onSubmitCreditCard()">

				<!--Number input-->
				<div class="relative mb-4 w-full">
					<label for="cardNumber" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
						transloco="form.card-number"></label>
					<input type="text" formControlName="cardNumber" [readonly]="isEditing"
						class="{{creditCardForm.get('cardNumber')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
						id="cardNumber" [placeholder]="'form.card-number' | transloco" />
					<span class="absolute top-7 ltr:right-2 rtl:left-2">
						@if (cardType !== "") {
						<img [src]="'assets/images/icons/payment/' + cardType + '.svg'" alt="card logo" class="w-[50px]">
						}
					</span>
				</div>

				<!--Name input-->
				<div class="mb-4 w-full">
					<label for="cardHolder" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
						transloco="form.card-name"></label>
					<input type="text" formControlName="cardHolder" [placeholder]="'form.card-name' | transloco"
						class="{{creditCardForm.get('cardHolder')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
						id="cardHolder" placeholder="" />
				</div>

				<!-- validate & CVV -->
				<div class="flex gap-8">
					<div class="mb-4 w-2/3">
						<label for="cardExpiration" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
							transloco="form.card-exp-date"></label>
						<div class="flex gap-2">
							<!-- EXPIRATION MONTH -->
							<select formControlName="cardExpirationMM" placeholder="MM" name='expireMM' id='expireMM'
								class="{{creditCardForm.get('cardExpirationMM')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-1/2 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
								<option value='' selected disabled class="text-greyDark" transloco="form.card-exp-month">
								</option>
								@for (month of months; track $index) {
								<option [value]="month">{{ month }}</option>
								}
							</select>
							<!-- EXPIRATION YEAR -->
							<select formControlName="cardExpirationYY" placeholder="YY" name='expireYY' id='expireYY'
								class="{{creditCardForm.get('cardExpirationYY')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-1/2 rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey">
								<option value='' selected disabled class="text-greyDark" transloco="form.card-exp-year">
								</option>
								@for (year of yearsToShow; track $index) {
								<option [value]="year">{{ year }}</option>
								}
							</select>
						</div>
					</div>
					<!-- CVV -->
					<div class="mb-4 w-1/2">
						<label for="cardCVV" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
							transloco="form.card-cvv"></label>
						<input type="password" formControlName="cardCVV" [placeholder]="'form.card-cvv' | transloco"
							class="{{creditCardForm.get('cardCVV')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
							id="cardCVV" placeholder="" />
					</div>
				</div>

				@if(!showRemoveCardPopUp) {
				<!-- DEFAULT CARD & TERMS CHECKBOX -->
				<div class="flex flex-col mt-8 gap-2">
					@if (!isEditing || (isEditing && editingCard?.isPrimary === false)) {
					<div class="flex items-center">
						<input type="checkbox" id="isDefaultCard" formControlName="isDefaultCard"
							class="border-greyDark mx-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded border-[0.125rem] border-solid outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:mx-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent">
						<label for="isDefaultCard" class="text-sm" transloco="profile-vn.billing.payment-methods.default-card">
						</label>
					</div>
					}
					<div class="flex items-center">
						<input type="checkbox" id="termsAndConditions" formControlName="termsAndConditions"
							class="{{ creditCardForm.get('termsAndConditions')?.invalid && formSubmitted  ? 'border-danger': 'border-greyDark'}} mx-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded border-[0.125rem] border-solid  outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:mx-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent">
						<label for="termsAndConditions" class="text-sm"
							[innerHTML]="'profile-vn.billing.payment-methods.terms-conditions' | transloco">
						</label>
					</div>
				</div>


				<div class="flex gap-4">
					@if (isEditing && editingCard.isPrimary === false) {
					<button type="button" class="flex justify-center border hover:border-grey py-3 px-4 rounded-xl mt-4 w-full"
						(click)="onClickRemoveCard()">
						<span transloco="btn-vn.remove"></span>
						<img src="assets/images/icons/profile/remove.svg" alt="remove icon" class="w-5">
					</button>
					}
					<!-- CONTINUE BUTTON -->
					<button type="submit" class="bg-orange text-white py-3 px-4 rounded-xl mt-4 w-full">
						<span transloco="btn-vn.continue"></span>
					</button>
				</div>
				}@else {
				<div class="flex flex-col items-center py-6">
					<span class="text-center font-medium text-red"
						transloco="profile-vn.billing.payment-methods.sure-to-delete-card"></span>
					<div class="flex w-full gap-4">
						<button type="button" class="flex justify-center border hover:border-grey py-3 px-4 rounded-xl mt-4 w-full"
							(click)="showRemoveCardPopUp = false">
							<span transloco="btn-vn.no"></span>
						</button>
						<button type="button"
							class="flex justify-center gap-2 border hover:border-red hover:text-red py-3 px-4 rounded-xl mt-4 w-full"
							(click)="removePaymentMethod(editingCard.creditCardId)">
							<span transloco="btn-vn.yes"></span>
							<img src="assets/images/icons/profile/remove.svg" alt="remove icon" class="w-5">
						</button>
					</div>
				</div>
				}
			</form>
			} @else {
			<form [formGroup]="addressCardForm" (ngSubmit)="onSubmitAddressCard()">
				<!--Address input-->
				<div class="mb-4">
					<label for="address" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
						transloco="form.address"></label>
					<input type="text" formControlName="address" [placeholder]="'form.address' | transloco"
						class="{{addressCardForm.get('address')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
						id="address" />
				</div>

				<!-- City & zip inputs -->
				<div class="flex gap-8">
					<!-- CITY FIELD -->
					<div class="mb-4 w-1/2">
						<label for="city" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.city"></label>
						<input type="text" formControlName="city" [placeholder]="'form.city' | transloco"
							class="{{addressCardForm.get('city')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
							id="city" />
					</div>
					<!-- ZIP CODE FIELD -->
					<div class="mb-4 w-1/2">
						<label for="zipCode" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
							transloco="form.zip-code"></label>
						<input type="text" formControlName="zipCode" [placeholder]="'form.zip-code' | transloco"
							class="{{addressCardForm.get('zipCode')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
							id="zipCode" />
					</div>
				</div>

				<!-- Country & State inputs -->
				<div class="flex gap-8">
					<!-- COUNTRY FIELD -->
					<div class="mb-4 w-1/2">
						<label for="country" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
							transloco="form.country"></label>
						<select type="text" formControlName="country" (change)="onCountryChange($event)"
							class="{{addressCardForm.get('country')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
							id="country">
							@for (country of countries; track $index) {
							<option class="" value={{country.countryID}}>{{country.countryName}}</option>
							}
						</select>
					</div>
					<!-- STATE FIELD -->
					<div class="mb-4 w-1/2">
						<label for="state" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
							transloco="form.state"></label>
						<select type="text" formControlName="state"
							class="{{addressCardForm.get('state')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
							id="state">
							@for (state of states; track $index) {
							<option class="" value={{state.stateID}}>{{state.state}}</option>
							}
						</select>

					</div>
				</div>

				<div class="flex gap-4">
					<!-- BACK BUTTON -->
					<button type="button" class="border hover:border-grey py-3 px-4 rounded-xl mt-4 w-full" (click)="goBack()">
						<span transloco="btn-vn.back"></span>
					</button>
					<!-- CONTINUE BUTTON -->
					<button type="submit" class="bg-orange text-white py-3 px-4 rounded-xl mt-4 w-full">
						<span transloco="btn-vn.continue"></span>
					</button>
				</div>
			</form>
			}

		</article>
	</div>

</div>
}@else {
<app-spinner></app-spinner>
}