<div>
  <h3 class="pb-8" transloco="payment.use-saved-methods"></h3>

  <div class="flex flex-wrap items-center gap-4 pb-6">
    @for (card of savedCards; track $index) {
    <div (click)="selectCard(card.creditCardId)"
      [class]="{'border-orange': isCardSelected(card.creditCardId)}"
      class="relative flex items-center rounded-2xl shadow-xl px-4 py-6 border border-dashed border-greyDark w-max hover:scale-105 hover:shadow-md cursor-pointer  focus:ring-2 focus:ring-orange">
      <div class="flex items-center">
        <input type="checkbox" [checked]="isCardSelected(card.creditCardId)" (change)="selectCard(card.creditCardId)"
          class="float-left ltr:mr-4 rtl:ml-4 h-5 w-5 appearance-none rounded-full border-[0.125rem] border-solid  outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-orange checked:bg-orange checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ml-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent" />
      
        @if(card.creditCardType !== '') {
          <img [src]="'assets/images/icons/payment/' + card.creditCardType + '.svg'" alt="card type"
            class="w-12 h-9 my-auto">
        }@else {
          <img [src]="'assets/images/icons/payment/chip.svg'" alt="card type" class="w-12 h-9 my-auto">
        }
        <div class="px-4">
          <h6 class=" text-sm font-semibold">•••• •••• {{ card.lastFourNumbers }}</h6>
          <div class="text-xs text-grey">
            <span transloco="profile-vn.billing.payment-methods.expires-on"></span>
            {{ card.expirationMonth + '/' + card.expirationYear.slice(-2) }}
          </div>
        </div>
      </div>
      @if(card.isPrimary === true) {
      <span>
        <img src="assets/images/icons/profile/star.svg" alt="" class="w-16 absolute -top-1.5 -right-3">
      </span>
      }
    </div>
    }
  </div>
</div>