<div class="fixed inset-0 w-full bg-white bg-opacity-90 flex flex-1 items-center z-[1000] transition-opacity duration-500 ease-in-out">
  <div class="pulse-animation-custom flex flex-col items-center w-full mx-8 text-center ">
    <img src="assets/images/logo.webp" alt="globusim logo" class="w-[30rem] h-fit mx-auto">
    
    <div class="mt-4 flex items-center">
      <p class="text-2xl font-medium text-grey tracking-wider" transloco="spinner-vn.loading"></p>
      <div class="dot"></div>
      <div class="dot dot-delay"></div>
      <div class="dot dot-delay-2"></div>
    </div>
  </div>
</div>
