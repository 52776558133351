import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthService } from '@app/services/auth/auth.service';

@Component({
  selector: 'app-payment-form',
  standalone: true,
  imports: [TranslocoModule, ReactiveFormsModule],
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent {
  authService = inject(AuthService);
  
  paymentForm = this.submitOrderService.submitForm.get('paymentForm') as FormGroup;
  formSubmitted: boolean = false;
  cardType: string = '';
  currentYear: number = new Date().getFullYear();
  yearsToShow: number[] = [];
  months: string[] = Array.from({ length: 12 }, (_, i) =>
    this.formatNumberOfMonth(i + 1)
  );
  
  showPopUp: boolean = false;

  constructor(private submitOrderService: SubmitOrderService,) {
    this.generateYearsOption();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.paymentForm
    .get('cardNumber')
    ?.valueChanges.subscribe((cardNum: string) => {
      this.cardType = this.detectCardType(cardNum);
    });
  }

  formatNumberOfMonth(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  generateYearsOption(): void {
    const yearsCount = 10;
    for (let i = 0; i < yearsCount; i++) {
      this.yearsToShow.push(this.currentYear + i);
    }
  }

  onSubmitPayment() {
    this.formSubmitted = true;

    if (this.paymentForm.valid) {
      this.submitOrderService.setOrderData(this.paymentForm.value);
      this.formSubmitted = false;
    }
  }

  detectCardType(cardNumber: string): string {
    // Remove all non-digit characters from the card number
    const cleanNumber = cardNumber.replace(/\D/g, '');
  
    if (/^4/.test(cleanNumber)) return 'visa';    // Visa
    if (/^(5[1-5]|2[2-7])/.test(cleanNumber)) return 'mastercard';    // Mastercard
    if (/^3[47]/.test(cleanNumber)) return 'amex';    // American Express
    if (/^3(0[0-5]|[68])/.test(cleanNumber)) return 'diners';    // Diners Club
    if (/^6(?:011|5)/.test(cleanNumber)) return 'discover';    // Discover
    return 'unknown';    // If no match is found
  }
}
