import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import { InfoPopUpComponent } from '../../pop-up/info-pop-up/info-pop-up.component';
import { SpinnerComponent } from '../../loading/spinner/spinner.component';
import { TranslocoModule } from '@jsverse/transloco';
import { CountriesService } from 'src/app/services/countries/countries.service';
import { RouterModule } from '@angular/router';
// import { IDidCountries, IPackageCountries } from 'src/app/services/models/countries.model';

@Component({
  selector: 'app-register-form',
  standalone: true,
  imports: [InfoPopUpComponent, ReactiveFormsModule, RouterModule, SpinnerComponent, TranslocoModule],
  templateUrl: './register-form.component.html',
  styleUrl: './register-form.component.scss'
})
export class RegisterFormComponent {
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  countryService = inject(CountriesService);

  // vNumberCountries: IDidCountries[];
  // packageCountries: IPackageCountries[];
  vNumberCountries: any;
  packageCountries: any;

  registerForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    name: ['', Validators.required],
    packageCountry: ['', Validators.required],
    vNumberCountry: ['', Validators.required],
    zipCode: ['', Validators.required],
    phone: ['', Validators.required, this.phoneNumberValidator],
    dialCode: [''],
  });
  formSubmitted: boolean = false;
  showPopUp: boolean = false;
  isLoading: boolean = false;
  popUpMessage: string = "";

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.getVNCountries();
    this.getPackageCountries();

  }
  
  async onSubmit() {
    this.formSubmitted = true;
    this.isLoading = true;
    
    if (this.registerForm.valid) {
      this.formSubmitted = false;
      this.isLoading = false;
    }
    
  }
  
  async getVNCountries() {
    const res = await this.countryService.getDIDCountries();
    this.vNumberCountries = res.result;
    console.log(this.vNumberCountries);
  }

  async getPackageCountries() {
    const res = await this.countryService.getPackageCountries();
    this.packageCountries = res.result;
    console.log(this.packageCountries);
  }

  ngAfterViewInit() {
    const inputElement = this.phoneInput.nativeElement;
    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        initialCountry: 'US',
        separateDialCode: true,
        formatOnDisplay: true,
        strictMode: true,
        utilsScript:
          'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
      });

      inputElement.addEventListener('input', () => {
        const phoneNumber = iti.getNumber();
        const dialCode = iti.getSelectedCountryData().dialCode;
        const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();

        // this.selectedCountryName = iti.getSelectedCountryData().name;
        this.registerForm.get('phone').setValue(nationalNumber);
        this.registerForm.get('dialCode').setValue(dialCode);
        this.registerForm.get('phone').updateValueAndValidity();
      });
    }
  }

  phoneNumberValidator(
    control: FormControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve) => {
      const cleanedNumber = control.value;

      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement;
        const iti = intlTelInput.getInstance(input);

        if (iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }

  onClosePopUp() {
    this.showPopUp = false;
  }
}
