<section class="rounded-xl shadow-full border border-greyLight pb-9">
  <h2 class="rounded-xl sticky top-0 z-10 bg-white text-2xl font-bold py-9 px-6 md:px-16"
    transloco="profile-vn.billing.billing-history.title">
  </h2>

  <article class="{{ billingHistory.length > 0 ? 'min-h-[370px]' : ''}} w-full px-6 md:px-16">
    <div class="scrollbar-sec overflow-x-auto">
      <table class="w-full min-w-[600px]">
        <thead>
          <tr class="border-b">
            <th class="py-3 ltr:text-left rtl:text-right text-sm whitespace-nowrap" transloco="profile-vn.billing.billing-history.detail.title"></th>
            <th class="py-3 ltr:text-left rtl:text-right text-sm whitespace-nowrap" transloco="profile-vn.billing.billing-history.status.title"></th>
            <th class="py-3 ltr:text-left rtl:text-right text-sm whitespace-nowrap" transloco="profile-vn.billing.billing-history.date.title"></th>
            <th class="py-3 ltr:text-left rtl:text-right text-sm whitespace-nowrap" transloco="profile-vn.billing.billing-history.charged.title"></th>
          </tr>
        </thead>
        <tbody>
          @for (entry of billingHistory; track $index) {
          <tr class="border-b last:border-b-0">
            <td class="py-6 text-sm whitespace-nowrap">
              {{ entry.transactionType }}
            </td>
            <td class="py-6 text-sm whitespace-nowrap">
              <span class="px-2.5 py-0.5 rounded" [class]="entry.transactionStatus | transactionStatusColor"
                [transloco]="'profile-vn.billing.billing-history.status-' + entry.transactionStatus.toLowerCase().replaceAll(' ', '-')">
              </span>
            </td>
            <td class="py-6 text-sm whitespace-nowrap">
              {{ entry.transactionDate }}
            </td>
            <td class="py-6 text-sm whitespace-nowrap">
              ${{ entry.transactionAmount.toFixed(2) }}
            </td>
            <td class="py-6 text-sm whitespace-nowrap">
              @if (!isSendingEmail[entry.transactionID] && !emailSentStatus[entry.transactionID]) {
              <button class="text-blue-600 hover:text-blue flex items-center gap-2"
                (click)="emailBillingReceipt(entry.transactionID)"
                [disabled]="isSendingEmail[entry.transactionID] || emailSentStatus[entry.transactionID] === 'success'">
                <img src="assets/images/icons/profile/download.svg" alt="" class="w-4 h-4">
                <span class="" transloco="profile-vn.billing.billing-history.email-receipt"></span>
              </button>
              } @else if (isSendingEmail[entry.transactionID]) {
              <app-spinner-small></app-spinner-small>
              } @else if (emailSentStatus[entry.transactionID] === 'success') {
              <span class="text-green font-medium" transloco="profile-vn.billing.billing-history.email-sent"></span>
              } @else if (emailSentStatus[entry.transactionID] === 'failed') {
              <span class="text-red font-medium" transloco="profile-vn.billing.billing-history.email-failed"></span>
              }
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </article>
  @if (billingHistory.length > 0) {
    <app-pagination [pageIndex]="currentPageIndex" [totalRecordsPerPage]="totalRecordsPerPage"
      [hasMoreRecords]="hasMoreRecords" [isLoading]="isLoading" (pageChange)="onPageChange($event)">
    </app-pagination>
  }@else {
    <div class="flex items-center justify-center text-sm font-medium border py-2 px-6 rounded-xl w-fit mx-auto"
      transloco="profile-vn.billing.billing-history.no-billing"></div>
  }
</section>