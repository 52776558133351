import { Component, inject, Signal, signal } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { PaymentMethodsService } from '@app/services/payment-methods/payment-methods.service';
import { ICreditCardInfo } from '@app/services/models/credit-card-info.model';
import { BillingHistoryComponent } from '../billing-history/billing-history.component';
import { PaymentMethodsComponent } from '../payment-methods/payment-methods.component';

@Component({
  selector: 'billing',
  standalone: true,
  imports: [BillingHistoryComponent, PaymentMethodsComponent, TranslocoModule],
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent {
  paymentMethodsService = inject(PaymentMethodsService)

  savedCards: ICreditCardInfo[] = [];
  showCreditCard: boolean = false;
  isEditing = false;
  editingCardId: number = 0;

  ngOnInit(): void {
    this.getAllSavedCards();
  }

  onPaymentUpdated(): void {
    this.getAllSavedCards();
  }

  async getAllSavedCards() {
    try {
      const res = await this.paymentMethodsService.getAllSavedCreditCards<ICreditCardInfo[]>()

      if (res.isSuccessful && res.result) {
        this.savedCards = res.result
      }
    } catch (error) {
      console.log(error)
    }
  }

  showCreditCardForm(): void {
    this.showCreditCard = true;
    this.isEditing = false;
    this.editingCardId = null;
  }

  editCard(card: ICreditCardInfo): void {
    this.isEditing = true;
    this.editingCardId = card.creditCardId;
    this.showCreditCard = true;
  }

  onCloseModal() {
    this.showCreditCard = false
  }
}
