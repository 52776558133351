<section class="rounded-xl shadow-full border border-greyLight py-9 px-6 md:px-16 w-full">
  <h2 class="text-2xl font-bold mb-8" transloco="profile-vn.my-vnumbers.global-numbers"></h2>

  <!-- <div class="mb-4">
    <label class="block text-sm font-medium text-gray-700">Receive SMS also in your email address</label>
    <div class="mt-1 flex rounded-md shadow-sm">
      <input type="email" class="flex-1 rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Enter your email here">
      <button class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
        ENVIAR
      </button>
    </div>
  </div> -->

  <div class="text-sm">
    @for (num of numbers; track $index) {
    <div class="flex flex-col sm:flex-row sm:items-center w-full gap-4 py-5 border-t border-greyLight">
      <div class="flex items-center gap-4 pr-16">
        <img [src]="num.countryName.replaceAll(' ', '-') | appFlags" class="w-8">
        <span class="font-semibold">{{ num.number }}</span>
      </div>
      <div class="flex flex-1 items-center">
        <label class="inline-flex relative items-center cursor-pointer">
          <input type="checkbox" [checked]="num.active" class="sr-only peer">
          <div
            class="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green">
          </div>
        </label>
        <span class="ml-3 text-sm font-medium text-gray-700">
          {{ (num.active ? 'profile-vn.my-vnumbers.active-check' : 'profile-vn.my-vnumbers.deactivate-check') |
          transloco }}
        </span>
        <button class="ml-auto text-sm text-blue hover:text-blue"
          transloco="profile-vn.my-vnumbers.forwarding"></button>
      </div>
    </div>
    }
  </div>
</section>