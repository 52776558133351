import { Component, inject } from '@angular/core';
import { PhoneFormatPipe } from '@app/pipes/phone-format.pipe';
import { CallHistoryService } from '@app/services/call-history/call-history.service';
import { ICallHistory } from '@app/services/models/call-history.model';
import { TranslocoModule } from '@jsverse/transloco';
import { RandomColorPipe } from 'src/app/pipes/random-color.pipe';
import { CallHistoryListComponent } from '../call-history-list/call-history-list.component';

@Component({
  selector: 'app-call-history',
  standalone: true,
  imports: [CallHistoryListComponent, TranslocoModule, PhoneFormatPipe, RandomColorPipe],
  templateUrl: './call-history.component.html',
  styleUrl: './call-history.component.scss',
})
export class CallHistoryComponent {
  callHistoryService = inject(CallHistoryService);
  recentCalls: ICallHistory[] = [];
  previousCalls: ICallHistory[] = [];

  constructor() {}

  ngOnInit(): void {
    this.getCallHistory();
  }

  async getCallHistory() {
    try {
      const res = await this.callHistoryService.getCallHistory<ICallHistory[]>()

      if (res.isSuccessful && res.result) {
        const currentDate = new Date();
        const currentDay = currentDate.getDay(); // current day of the week (0-6)
        const startOfWeek = new Date(currentDate); // Copy the current date
        startOfWeek.setDate(currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)); // Ajusta al lunes de esta semana

        // Filter this week's recent calls
        this.recentCalls = res.result.filter((call: ICallHistory) => {
          const callDate = new Date(call.dialedTime);
          return callDate >= startOfWeek;
        });

        // Filter previous calls
        this.previousCalls = res.result.filter((call: ICallHistory) => {
          const callDate = new Date(call.dialedTime);
          return callDate < startOfWeek;
        });
      }
    } catch (error) {
      
    }
  }
}
