<section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
  <h2 class="flex flex-col text-2xl mb-6" [innerHTML]="'profile-vn.support.access-numbers.title' | transloco"></h2>

  <h3 class="text-base font-medium py-8" transloco="profile-vn.support.access-numbers.subtitle"></h3>
  <article class="flex flex-col md:flex-row gap-12">

    <div class="w-full md:w-1/2">
      <form [formGroup]="accessNumbersForm" (ngSubmit)="onSubmitAccessNumbers()">
        <!-- COUNTRY FIELD -->
        <div class="mb-4">
          <label for="country" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm text-grey"
            transloco="form.country"></label>
          <select type="text" formControlName="country" (change)="onCountryChange($event)"
            class="{{accessNumbersForm.get('country')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
            id="country">
            @for (country of countries; track $index) {
            <option class="" value={{country.countryID}}>{{country.countryName}}</option>
            }
          </select>
        </div>
        <!-- Country & State inputs -->
        <div class="flex gap-8">
          <!-- STATE FIELD -->
          <div class="mb-4 w-1/2">
            <label for="state" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm text-grey"
              transloco="form.state"></label>
            <select type="text" formControlName="state"
              class="{{accessNumbersForm.get('state')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
              id="state">
              @for (state of states; track $index) {
              <option class="" value={{state.stateID}}>{{state.state}}</option>
              }
            </select>

          </div>
          <!-- AREA CODE FIELD -->
          <div class="mb-4 w-1/2">
            <label for="areaCode" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm text-grey"
              transloco="form.area-code"></label>
            <input type="text" formControlName="areaCode" [placeholder]="'form.area-code' | transloco"
              class="{{accessNumbersForm.get('areaCode')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
              id="areaCode" />
          </div>
        </div>

        <div class="flex gap-4">
          <!-- CONTINUE BUTTON -->
          <button type="submit" class="bg-orange text-white py-3 px-4 rounded-xl mt-4 w-full">
            <span transloco="btn-vn.continue"></span>
          </button>
        </div>
      </form>
    </div>

    <div class="w-full md:w-1/2 py-6">
      <ul class="flex flex-col">
        @for (number of numbers; track $index) {
          <li class="flex justify-between py-2 border-b ">
            {{ number }}
            <a [href]="'tel:+' + number">
              <img _ngcontent-ng-c3879184384="" src="assets/images/icons/profile/call.svg" alt="" class="w-6">
            </a>
          </li>
        }
      </ul>
    </div>
  </article>

</section>