import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { FlagsPipe } from 'src/app/pipes/flags.pipe';

@Component({
  selector: 'app-global-numbers',
  standalone: true,
  imports: [FlagsPipe, TranslocoModule],
  templateUrl: './global-numbers.component.html',
  styleUrl: './global-numbers.component.scss'
})
export class GlobalNumbersComponent {
  numbers = [
    { country: 'US', number: '877-800-5141', active: true, countryName: 'united states' },
    { country: 'BR', number: '219-330-0708', active: false, countryName: 'brazil' }
  ];

  saveChanges() {
    console.log('Changes saved');
  }
}
