<footer class="bg-[#333232] px-5 text-white">
  <div class="block mlg:flex justify-center py-10">
    <section class="flex flex-col w-full items-center mlg:items-start mlg:w-max mlg:pr-20 rtl:mlg:pl-20">
      <img src="assets/images/logo-w.webp" alt="Logo Desktop" class="w-[15rem] lg:w-[10rem] h-fit" loading="lazy">

      <div class="py-5 flex flex-col gap-6 md:gap-2">
        <a href="mailto:info@vnumber.com" class="text-sm md:text-base">info&#64;vnumber.com</a>
        <a href="mailto:orders@vnumber.com" class="text-sm md:text-base">orders&#64;vnumber.com</a>
      </div>
      <!-- <div class="flex py-8 gap-5">
        <a href="https://www.facebook.com/people/Globusim/100057373460578/" target="_blank"><img src="assets/images/icons/facebook.svg" alt="facebook icon" class="w-[2rem] h-[2rem] img-rotate"></a>
        <a href="http://www.twitter.com/globusim" target="_blank"><img src="assets/images/icons/twitter.svg" alt="twitter icon" class="w-[2rem] h-[2rem] img-rotate"></a>
        <a href="http://www.youtube.com/@globusim6888" target="_blank"><img src="assets/images/icons/youtube.svg" alt="youtube icon" class="w-[2rem] h-[2rem] img-rotate"></a>
        <a href="http://www.instagram.com/globusim4travel" target="_blank"><img src="assets/images/icons/instagram.svg" alt="instagram icon" class="w-[2rem] h-[2rem] img-rotate"></a>
        <a href="https://www.linkedin.com/showcase/globusim/" target="_blank"><img src="assets/images/icons/linkedin.svg" alt="linkedin icon" class="w-[2rem] h-[2rem] img-rotate"></a>
      </div> -->
    </section>
    
    <section class="grid grid-cols-2 md:grid-cols-4 gap-2 lg:gap-6 py-4">
      <!-- CONTACT US -->
      <article class="wide:px-8 md:min-w-[220px] pb-8">
        <h4 class="font-semibold pb-4" transloco="footer-vn.title-contact"></h4>
        <ul class="text-sm xl:text-base">
          <li class="cursor-pointer flex flex-col sm:flex-row"> <span transloco="footer-vn.contact-link-usa"></span><a href="tel:+1-855-613-8188">1-855-613-8188</a> </li>
          <li class="cursor-pointer flex flex-col sm:flex-row"> <span transloco="footer-vn.contact-link-canada"></span><a href="tel:+1-855-613-8188">1-855-613-8188</a> </li>
          <li class="cursor-pointer flex flex-col sm:flex-row"> <span transloco="footer-vn.contact-link-uk"></span><a href="tel:+44-20-369-52-339">44-20-369-52-339</a> </li>
          <!-- <li class="cursor-pointer flex flex-col sm:flex-row"> <span transloco="footer.contact-link-israel"></span><a href="tel:+972-516-500-600">972-51-650-0600</a> </li> -->
        </ul>
      </article>
      <!-- THE COMPANY -->
      <article class="wide:px-8 pb-8">
        <h4 class="font-semibold pb-4" transloco="footer-vn.title-company"></h4>
        <ul class="text-sm xl:text-base">
          <li class="cursor-pointer hover:scale-x-105" routerLink="about" transloco="footer-vn.company.about-us"> </li>
          <li class="cursor-pointer hover:scale-x-105" routerLink="contact" transloco="footer-vn.company.contact-us"> </li>
        </ul>
      </article>
      <!-- QUICK LINKS -->
      <article class="wide:px-8 pb-8">
        <h4 class="font-semibold pb-4" transloco="footer-vn.title-links"></h4>
        <ul class="text-sm xl:text-base">
          <li class="cursor-pointer hover:scale-x-105" routerLink="faq" transloco="footer-vn.quick-links.faq"></li>
          <li class="cursor-pointer hover:scale-x-105" routerLink="privacy-policy" transloco="footer-vn.quick-links.privacy-policy"></li>
          <li class="cursor-pointer hover:scale-x-105" routerLink="terms-and-conditions" transloco="footer-vn.quick-links.terms"></li>
        </ul>
      </article>
      <!-- LOOKING FOR -->
      <article class="wide:px-8 pb-8">
        <h4 class="font-semibold pb-4" transloco="footer-vn.title-looking"></h4>
        <ul class="text-sm xl:text-base">
          <!-- <li class="cursor-pointer hover:scale-x-105" routerLink="get-your-sim/united-states/esim" transloco="footer.looking-for-american"></li>
          <li class="cursor-pointer hover:scale-x-105" routerLink="get-your-sim/israel/esim" transloco="footer.looking-for-israeli"></li>
          <li class="cursor-pointer hover:scale-x-105" routerLink="get-your-sim/europe/data" transloco="footer.looking-for-european"></li>
          <li class="cursor-pointer hover:scale-x-105" routerLink="get-your-sim/israel_long_term/long-term" transloco="footer.looking-for-long-term"></li> -->
        </ul>
      </article>
    </section>
  </div>

  <!-- <span class="flex justify-center text-center w-full text-grey py-4" transloco="footer.copyright"></span> -->
</footer>