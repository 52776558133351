<div class="relative max-w-[760px] px-4 overflow-hidden">
  <div class="flex transition-transform duration-1000 ease-in-out" [ngStyle]="{'transform': 'translateX(' + (-currentIndex * 100) + '%)'}">
    <div *ngFor="let testimonial of testimonials" class="min-w-full flex flex-col items-center text-center sm:p-4">
      <div class="flex justify-center gap-6 pb-12">
        <article class="relative shadow-full rounded-3xl py-12 px-4 smd:px-20 w-full sm:w-[95%] h-[240px]">
          <img [src]="'assets/images/icons/testimonialvnumber.svg'" alt="" class="w-24 absolute left-0 top-5">
          <h3 class="pt-8" [transloco]="'testimonial.'+ testimonial.name"></h3>
          <p class="text-grey" [transloco]="'testimonial.'+ testimonial.text"></p>
        </article>
      </div>
    </div>
  </div>

  <!-- Navigation Buttons -->
  <button (click)="prev()" class="hidden lg:flex absolute left-0 text-3xl opacity-50 p-2 rounded-full hover:opacity-100 focus:outline-none">
    &#10094;
  </button>
  <button (click)="next()" class="hidden lg:flex absolute right-0 text-3xl opacity-50 p-2 rounded-full hover:opacity-100 focus:outline-none">
    &#10095;
  </button>

  <!-- Pagination Dots -->
  <div class="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-5">
    <span *ngFor="let testimonial of testimonials; let i = index"
          class="w-4 h-4 rounded-full bg-orange cursor-pointer {{ i !== currentIndex ? 'opacity-50' : 'opacity-100'}}" 
          (click)="goToSlide(i)">
    </span>
  </div>
</div>
