import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { ControllerApiList } from '../models/controllers/ControllerList';
import { lastValueFrom } from 'rxjs';
import { PersistenceService } from '../persistence/persistence.service';
import { LocalStorageList } from 'src/app/helpers/enums/enums.keys';
import { UserInformation } from '../models/user-information.model';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedIn: boolean = false;
  private persistenceService = inject(PersistenceService)

  constructor (private http: HttpClient, private settings: SettingsService) {}

  async login(username: string, password: string) {
    const user = this.http
      .post<UserInformation>(`${this.settings.getHubBaseUrlApi}/${ControllerApiList.HubApi.VnumberApi.SignIn}`, {
        username,
        password,
      })
    const dataResponse = await lastValueFrom(user);

    if (dataResponse && dataResponse.token) {
      // const helper = new JwtHelperService();
      // const decodedToken = helper.decodeToken(dataResponse.token);

      this.persistenceService.setData(LocalStorageList.currentUser, dataResponse, true);
    }
    return dataResponse;
  }

  logout(redirect = true) {
    this.clearLocalStorage()

    if (redirect) {
      document.location.href = "/";
    } else {
      document.location.reload();
    }
  }
  
  clearLocalStorage() {
    this.persistenceService.removeData(LocalStorageList.currentUser);
    this.persistenceService.removeData(LocalStorageList.expiration);
    this.persistenceService.removeData(LocalStorageList.name);
    this.persistenceService.removeData(LocalStorageList.refresh_token);
    this.persistenceService.removeData(LocalStorageList.token);
  }

  IsLogin(): boolean {
    const exp = this.getExpirationToken();
    if (!exp) {
      return false;
    }
    const now = new Date().getTime();
    const dateExp = new Date(exp);

    if (now >= dateExp.getTime()) {
      // ya expiró el token
      this.logout();
      return false;
    } else {
      return true;
    }
  }

  getCurrentUser() {
    return this.persistenceService.getData(LocalStorageList.currentUser, true);
  }

  getExpirationToken(): string {
    const json = this.getCurrentUser();
    return json == null ? null : json.expiration;
  }

  getToken(): string {
    const json = this.getCurrentUser();
    return json === null ? "" : json.token;
  }

  getRefreshToken(): string {
    const json = this.getCurrentUser();
    return json === null ? "" : json.refresh_token;
  }
  
  getUser(): string {
    const json = this.getCurrentUser();
    return json === null ? "" : json.user;
  }

  getHistoryCall() {
    
  }
}
