<div class="flex flex-col gap-8">
  <section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
    <h2 class="text-2xl font-bold mb-16" transloco="profile-vn.free-credit.title"></h2>
    <div class="flex items-center gap-4">
      <span class="rounded-md p-3 bg-melocoton">
        <img src="assets/images/icons/profile/giftbox.svg" alt="giftbox" class="w-6 h-6">
      </span>
      <span class="font-semibold" transloco="profile-vn.free-credit.subtitle"></span>
    </div>

    <app-referral-stats [referred]="0" [credit]="0"></app-referral-stats>
  </section>

  <section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
    <h2 class="text-2xl font-bold mb-16" transloco="profile-vn.free-credit.enter-friend-info"></h2>

    <form [formGroup]="referralForm" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
        @for (friend of friends; track friend.id) {
          <app-friend-form 
            [formGroup]="friend.form" 
            [formNumber]="friend.id + 1">
          </app-friend-form>
        }
      </div>
      
      <div class="flex justify-between items-center py-6">
        <div class="flex items-center gap-4">
          <button type="button" (click)="addFriend()" class="bg-orange text-white text-2xl px-4 py-2 rounded-full"> + </button>
          <span class="text-sm font-semibold" transloco="profile-vn.free-credit.add-friend"></span>
        </div>
        
        <button type="submit" class="bg-orange text-white px-8 py-2 rounded-full" transloco="btn-vn.submit"></button>
      </div>
    </form>
  </section>
</div>