<section class="rounded-xl shadow-full border border-greyLight py-8 px-6 md:px-16 w-full">
  <h2 class="flex flex-col text-2xl mb-6" [innerHTML]="'profile-vn.add-credit.title' | transloco"></h2>

  <article class="">
    <h3 class="text-base font-medium pt-8" transloco="profile-vn.add-credit.subtitle"></h3>

    <div class="flex flex-wrap gap-4 py-16">
      @for (amount of [15, 25, 50]; track $index) {
        <button (click)="selectAmount(amount)" [class]="{'border-orange': customAmount === amount}"
          class="btn-effect flex py-2 px-6 sm:px-10 border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 focus:outline-none focus:ring-1 focus:ring-orange">
          <span class="text-lg text-green font-semibold">$ {{amount}}</span>
        </button>
      }
      <div class="relative flex items-center ">
        <input type="number" [(ngModel)]="customAmount" placeholder="-" maxlength="2"
          class=" w-32 py-3 px-8 font-semibold bg-greyLight rounded-xl shadow-sm focus:outline-none focus:ring-1 focus:ring-orange">
          <span class="text-lg  font-semibold absolute left-4">$</span>
      </div>
    </div>

    <app-credit-card></app-credit-card>
    <!-- <div class="flex justify-between items-center mb-6">
      <a href="#" class="text-orange-500 hover:text-orange-600 text-sm font-semibold">+ Asociar método de pago</a>
    </div> -->

    <button (click)="addCredit()" [disabled]="customAmount === null || customAmount === 0"
      class="{{ customAmount === null ? 'opacity-50' : 'hover:scale-105 transition duration-200' }} w-full bg-orange text-white uppercase font-semibold py-3 px-4 rounded-lg  " transloco="btn-vn.add-credit"></button>

  </article>
</section>