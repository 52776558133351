import { Component, inject, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSidebarComponent } from 'src/app/core/shared/profile/profile-sidebar/profile-sidebar.component';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthService } from 'src/app/services/auth/auth.service';
import { filter, Subscription } from 'rxjs';
import { AccountInfoService } from '@app/services/account-info/account-info.service';
import { IAccountInformation } from '@app/services/models/user-information.model';
import { SpinnerComponent } from '@app/core/shared/loading/spinner/spinner.component';
import { AddCreditComponent } from '@app/core/shared/profile/add-credit/add-credit.component';
import { BtnLogoutComponent } from '@app/core/shared/buttons/btn-logout/btn-logout.component';

@Component({
  standalone: true,
  imports: [
    AddCreditComponent,
    BtnLogoutComponent,
    ProfileSidebarComponent,
    RouterModule,
    SpinnerComponent,
    TranslocoModule,
  ],
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent {
  accountInfoService = inject(AccountInfoService);
  authService = inject(AuthService);

  currentChildRoute: string | null = null;
  isLoading: boolean = false;
  availableCredit: number = 0;

  constructor(private route: ActivatedRoute, private router: Router) {
    effect(() => {
      const signalValue = this.accountInfoService.addCreditSignal();

      if (signalValue === true) {
        console.log('credit added');
        this.getAccountInfo();
      }

      this.accountInfoService.onCreditAdded(false);

    }, {allowSignalWrites: true});
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.getAccountInfo();
    this.updateCurrentChildRoute();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateCurrentChildRoute();
      });
  }

  private updateCurrentChildRoute(): void {
    const childRoute = this.route.firstChild;
    if (childRoute && childRoute.snapshot.url.length > 0) {
      this.currentChildRoute = childRoute.snapshot.url[0].path;
    } else {
      this.currentChildRoute = null;
    }
  }

  logout() {
    this.authService.logout();
  }

  // animateCredit(start: number, end: number) {
  //   const duration = 1000; // Animation duration in milliseconds
  //   const startTime = performance.now(); // Initial time stamp

  //   const animate = (currentTime: number) => {
  //     const elapsedTime = currentTime - startTime;
  //     const progress = Math.min(elapsedTime / duration, 1); // Progress between 0 and 1

  //     // Interpolation between start and end
  //     this.availableCredit = start + (end - start) * progress;

  //     if (progress < 1) {
  //       requestAnimationFrame(animate);
  //     } else {
  //       this.availableCredit = end; // Ensuring that the final value is set correctly
  //     }
  //   };

  //   requestAnimationFrame(animate);
  // }

  async getAccountInfo() {
    try {
      const res =
        await this.accountInfoService.getAccountInfo<IAccountInformation>();

      if (res.isSuccessful && res.result) {
        this.availableCredit = res.result.currentBalance
        this.isLoading = false;
        // const newCredit = res.result.currentBalance;
        // this.animateCredit(this.availableCredit, newCredit);
      }
    } catch (error) {
      console.log(error);
    }
  }
}
