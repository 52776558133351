<div>
  <article class="flex flex-col-reverse items-center lg:flex-row lg:justify-center">
    <div class="hidden lg:flex py-12">
      <img src="assets/images/call.png" alt="">
    </div>
    <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()" class="p-10 rounded-3xl shadow-full w-full lg:max-w-[380px]">
      <div class="flex flex-col gap-5 justify-evenly py-4">
        <p transloco="choose-number.need-more-info.specialized-representative"></p>
        <!--Name input-->
        <div class="">
          <label for="name" class="max-w-[90%] font-medium" transloco="form.name"></label>
          <input type="text" formControlName="name" [placeholder]="'form.name' | transloco"
            class="{{contactForm.get('name')?.invalid && formSubmitted ? 'border-danger' : 'border-greyDark'}} w-full rounded border  bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey peer-invalid:border-red"
            id="fullName" />
        </div>
    
        <!--Email input-->
        <div class="">
          <label for="email" class="max-w-[90%] font-medium" transloco="form.email"></label>
          <input type="email" formControlName="email" id="email" [placeholder]="'form.email' | transloco"
            class="{{contactForm.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey peer-invalid:border-red" />
        </div>
    
        <!--Phone input-->
        <div class="flex flex-col">
          <label for="phoneInput" class="max-w-[90%] font-medium" transloco="form.phone"></label>
          <input #phoneInput type="tel" id="phoneInput" formControlName="phone" [placeholder]="'form.phone' | transloco"
            class="{{contactForm.get('phoneInput')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} w-full rounded border border-greyDark bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey peer-invalid:border-red" />
        </div>
      </div>
    
      <div class="flex justify-center py-4">
        <button type="submit" transloco="btn-vn.submit"
          class="{{ contactForm.valid ? 'transition delay-75 duration-200 hover:scale-110' : '' }} flex items-center text-orange border-2 border-orange font-semibold uppercase rounded-full px-16 py-3 w-max">
        </button>
      </div>
    </form>
  </article>

  <article class="flex flex-col lg:flex-row justify-center gap-20 py-10 text-grey text-xl font-medium">
    <div class="flex flex-col items-center">
      <img src="assets/images/icons/contact_1vnumber.svg" alt="" class="w-12 p-3 rounded-full shadow-full">
      
      <p class=" py-4">
        <span transloco="choose-number.need-more-info.call-us"></span> 
        <strong>1-877-993-9770</strong>
      </p>
    </div>
    <div class="flex flex-col items-center">
      <img src="assets/images/icons/contactvnumber.svg" alt="" class="w-12 p-3 rounded-full shadow-full">
      
      <p class="py-4" [innerHTML]="'choose-number.need-more-info.whatsapp' | transloco"></p>
    </div>
  </article>

  @if (showPopUp) {
    <app-info-pop-up
      [showPopUp]="showPopUp" 
      (closePopUpEvent)="onClosePopUp()" 
      [translate]="popUpMessage">
    </app-info-pop-up>
  }
</div>