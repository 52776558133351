import { Component } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { LangDefinition, TranslocoService } from '@jsverse/transloco';
import { cookiesStorage } from '@jsverse/transloco-persist-lang';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  textDir: string = 'ltr';
  
  constructor(
    private translate: TranslocoService,
    private route: ActivatedRoute,
    private scroller: ViewportScroller
  ) {
    this.verifyLanguage();
  }
      
  ngOnInit(): void {}

  // Verify user languague
  verifyLanguage() {
    const supportedLangs =
      this.translate.getAvailableLangs() as LangDefinition[];

    this.route.queryParams.subscribe((params) => {
      const lang = params['lang'];

      if (lang && supportedLangs.find((s) => s.id == lang)) {
        this.translate.setActiveLang(lang);
      } else {
        const storedLanguage = cookiesStorage().getItem('translocoLang');

        if (!storedLanguage) {
          const browserLang = navigator.language.split('-')[0];
          if (supportedLangs.find((s) => s.id == browserLang)) {
            this.translate.setActiveLang(browserLang);
          }
        }
      }
    });

    this.translate.langChanges$.subscribe((lang: string) => {
      if (lang == 'he') {
        this.textDir = 'rtl';
      } else {
        this.textDir = 'ltr';
      }
    });
  }

  onActivate(event: any) {
    window.scroll(0, 0);
  }

  ngAfterViewInit(): void {
    // this.scroller.scrollToPosition([0, 0]);
  }
}
