<section class="flex flex-col gap-y-6 w-full md:w-[440px]">
  <span transloco="login.email-log-subtitle" class="font-semibold"></span>

  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <!-- EMAIL -->
		<div class="mb-4 w-full">
			<label for="username" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm " transloco="form.email"></label>
			<input type="email" id="username" formControlName="username" [placeholder]="'form.email' | transloco"
				class="{{ loginForm.get('username')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
		</div>
    <!-- PASSWORD -->
    <div class="mb-9 w-full">
      <label class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" for="password" transloco="form.password"></label>
      <input type="password" id="password" formControlName="password"[placeholder]="'form.password' | transloco"  
      class="{{ loginForm.get('password')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
      <!-- FORGOT PASSWORD -->
      <p class="text-orange font-semibold" transloco="login.forgot-password"></p>
    </div>
    <!-- LOGIN BUTTON -->
    <div class="flex flex-col items-center justify-between">
      <button type="submit" transloco="btn-vn.log-in"
        class="bg-orange text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"></button>
        <!-- REGISTER -->
      <div class="flex w-full">
        <p class="text-start text-grey" transloco="login.dont-have-account"></p>&nbsp;
        <a class="inline-block align-baseline font-bold text-sm text-orange hover:cursor-pointer" routerLink="/register"
          transloco="login.register"></a>
      </div>
    </div>
  </form>

  <div class="mt-4 text-grey text-xs pb-24" [innerHTML]="'login.accept-terms-policy' | transloco"></div>

  @if (showPopUp) {
    <app-info-pop-up
      [showPopUp]="showPopUp" 
      (closePopUpEvent)="onClosePopUp()" 
      [translate]="popUpMessage">
    </app-info-pop-up>
  }

  @if (isLoading) {
    <app-spinner></app-spinner>
  }
</section>