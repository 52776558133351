<section class="flex flex-col gap-y-6 w-full md:w-[440px]">
	<!-- <span transloco="login.email-log-subtitle" class="font-semibold"></span> -->

	<form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
		<!-- NAME -->
		<div class="mb-4 w-full">
			<label for="name" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.name"></label>
			<input type="text" id="name" formControlName="name" [placeholder]="'form.name' | transloco"
				class="{{registerForm.get('name')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
		</div>
		<!-- EMAIL -->
		<div class="mb-4 w-full">
			<label for="email" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm " transloco="form.email"></label>
			<input type="email" id="email" formControlName="email" [placeholder]="'form.email' | transloco"
				class="{{registerForm.get('email')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
		</div>
		<!-- ZIP CODE & PHONE NUMBER -->
		<div class="flex gap-6 mb-4">
			<!-- ZIP CODE FIELD -->
			<div class="w-1/3">
				<label for="zipCode" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm"
					transloco="form.zip-code"></label>
				<input type="text" formControlName="zipCode" [placeholder]="'form.zip-code' | transloco"
					class="{{registerForm.get('zipCode')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
					id="zipCode" />
			</div>
			<!-- PHONE -->
			<div class="w-2/3">
				<label for="phoneInput" class="mb-0 max-w-[90%] leading-[1.6] font-medium text-sm" transloco="form.phone"></label>
				<input type="tel" id="phoneInput" formControlName="phone" #phoneInput
					class="{{registerForm.get('phone')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey" />
			</div>
		</div>
		<!-- VIRTUAL NUMBER COUNTRY -->
		<div class="mb-4 w-full">
			<label for="vNumberCountry" class="mb-0 max-w-[90%] leading-[1.6] font-medium" transloco="search-country.title-vnumber-country"></label>
			<select type="text" formControlName="vNumberCountry"
				class="{{registerForm.get('vNumberCountry')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
				id="vNumberCountry">
				<option value="null" selected disabled transloco="search-country.title-vnumber-country"></option>
				@for (country of vNumberCountries; track $index) {
					<option class="" value={{country.countryID}}>{{country.countryName}}</option>
				}
			</select>
		</div>
		<!-- PACKAGE COUNTRY -->
		<div class="mb-8 w-full">
			<label for="packageCountry" class="mb-0 max-w-[90%] leading-[1.6] font-medium" transloco="search-country.title-your-country"></label>
			<select type="text" formControlName="packageCountry"
				class="{{registerForm.get('packageCountry')?.invalid && formSubmitted ? 'border-danger': 'border-greyDark'}} block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:border-grey"
				id="packageCountry">
				<option value="null" selected disabled transloco="search-country.title-your-country"></option>
				@for (country of packageCountries; track $index) {
					<option class="" value={{country.countryID}}>{{country.countryName}}</option>
				}
			</select>
		</div>

		<div class="flex flex-col items-center justify-between pb-28">
			<button type="submit" transloco="btn-vn.log-in"
				class="bg-orange text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"></button>
			<div class="flex w-full">
				<p class="text-start text-grey" transloco="login.dont-have-account"></p>&nbsp;
				<a class="inline-block align-baseline font-bold text-sm text-orange hover:cursor-pointer" routerLink="/login"
					transloco="login.register"></a>
			</div>
		</div>
	</form>
	@if (showPopUp) {
	<app-info-pop-up [showPopUp]="showPopUp" (closePopUpEvent)="onClosePopUp()" [translate]="popUpMessage">
	</app-info-pop-up>
	}
</section>