import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileSidebarComponent } from 'src/app/core/shared/profile/profile-sidebar/profile-sidebar.component';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthService } from 'src/app/services/auth/auth.service';
import { filter } from 'rxjs';
import { AccountInfoService } from '@app/services/account-info/account-info.service';
import { IAccountInformation } from '@app/services/models/user-information.model';
import { SpinnerComponent } from '@app/core/shared/loading/spinner/spinner.component';

@Component({
  standalone: true,
  imports: [CommonModule, ProfileSidebarComponent, RouterModule, SpinnerComponent, TranslocoModule],
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent {

  accountInfoService = inject(AccountInfoService);
  authService = inject(AuthService);

  currentChildRoute: string | null = null;
  isLoading: boolean = false;
  availableCredit: number = 0;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.isLoading = true
    this.getAccountInfo();
    this.updateCurrentChildRoute();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateCurrentChildRoute();
    });
  }

  private updateCurrentChildRoute(): void {
    const childRoute = this.route.firstChild;
    if (childRoute && childRoute.snapshot.url.length > 0) {
      this.currentChildRoute = childRoute.snapshot.url[0].path;
    } else {
      this.currentChildRoute = null;
    }
  }

  logout() {
    this.authService.logout()
  }

  async getAccountInfo() {
    try {
      const res = await this.accountInfoService.getAccountInfo<IAccountInformation>()

      if (res.isSuccessful && res.result) {
        this.availableCredit = res.result.currentBalance
        this.isLoading = false
      }
    } catch (error) {
      console.log(error)
    }
  }
}
