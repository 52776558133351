import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { CountriesService } from '@app/services/countries/countries.service';
import { ICountries } from '@app/services/models/countries.model';
import { ICreditCardInfo } from '@app/services/models/credit-card-info.model';
import { IState } from '@app/services/models/states.model';
import { PaymentMethodsService } from '@app/services/payment-methods/payment-methods.service';
import { StatesService } from '@app/services/states/states.service';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { SpinnerComponent } from '../../../loading/spinner/spinner.component';

@Component({
  selector: 'app-payment-methods',
  standalone: true,
  imports: [ReactiveFormsModule, SpinnerComponent, TranslocoModule],
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent {
  paymentMethodsService = inject(PaymentMethodsService);

  @Input() isEditing: boolean = false;
  @Input() editingCardId: number;

  @Output() paymentUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModalEvent: EventEmitter<void> = new EventEmitter<void>();

  showRemoveCardPopUp: boolean = false;
  showAddressForm: boolean = false;
  flipped: boolean = false;
  formSubmitted: boolean = false;

  cardType: string = '';
  currentYear: number = new Date().getFullYear();
  yearsToShow: number[] = [];
  months: string[] = Array.from({ length: 12 }, (_, i) =>
    this.formatNumberOfMonth(i + 1)
  );

  countries: ICountries[] = [];
  states: IState[] = [];
  selectedCountryId: string = '';
  selectedState: string = '';
  currentLang: string = '';
  editingCard: ICreditCardInfo | null = null;

  creditCardForm = this.fb.group({
    cardHolder: [
      '',
      Validators.compose([Validators.required, Validators.minLength(3)]),
    ],
    cardNumber: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(13),
        Validators.maxLength(19),
        this.creditCardValidator
      ]),
    ],
    cardExpirationMM: ['', Validators.compose([Validators.required])],
    cardExpirationYY: ['', Validators.compose([Validators.required])],
    cardCVV: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
        Validators.pattern(/^\d{3,4}$/),
      ]),
    ],
    isDefaultCard: [false],
    termsAndConditions: [true, Validators.requiredTrue],
  });

  addressCardForm = this.fb.group({
    address: [
      '',
      Validators.compose([Validators.required, Validators.minLength(5)]),
    ],
    country: ['', Validators.required],
    state: ['', Validators.required],
    city: ['', Validators.required],
    zipCode: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    private countriesService: CountriesService,
    private statesService: StatesService
  ) {
    this.generateYearsOption();
    this.currentLang = this.translocoService.getActiveLang();
  }

  ngOnInit(): void {
    this.getCountries();
    
    this.addressCardForm
      .get('country')
      ?.valueChanges.subscribe((selectedCountry: string) => {
        this.selectedCountryId = selectedCountry;
      });

    if (this.isEditing && this.editingCardId) {
      this.getPaymentMethod();
    } else {
      this.creditCardForm
        .get('cardNumber')
        ?.valueChanges.subscribe((cardNum: string) => {
          this.cardType = this.detectCardType(cardNum);
        });
      this.creditCardForm
        .get('cardNumber')
        ?.setValidators([
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(16),
          Validators.pattern(/^\d{16}$/),
        ]);
      this.creditCardForm.get('cardNumber')?.updateValueAndValidity();
    }
  }

  formatNumberOfMonth(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  generateYearsOption(): void {
    const yearsCount = 10;
    for (let i = 0; i < yearsCount; i++) {
      this.yearsToShow.push(this.currentYear + i);
    }
  }

  creditCardValidator(control: FormControl) {
    const cleanedNumber = control.value.replace(/\s+/g, '').replace(/-/g, '');

    if (cleanedNumber) {
      const digits = cleanedNumber.split('').map(Number); // Convert the number into an array of digits
      let sum = 0;
      let double = false;

      for (let i = digits.length - 1; i >= 0; i--) {
        // Start from the last digit
        let digit = digits[i];

        if (double) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }

        sum += digit;
        double = !double;
      }

      if (sum % 10 !== 0) return { invalidCreditCard: true };
    }

    return null;
  }

  closeCCModal() {
    this.closeModalEvent.emit();
  }

  flipCard() {
    this.flipped = !this.flipped;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeCCModal();
    }
  }

  onSubmitCreditCard(): void {
    this.formSubmitted = true;
    if (this.creditCardForm.valid) {
      this.showAddressForm = true;
      this.formSubmitted = false;
    }
  }

  onSubmitAddressCard(): void {
    if (this.addressCardForm.valid) {
      if (this.isEditing) {
        this.editPaymentMethod();
      } else {
        this.addPaymentMethod();
      }
    }
  }

  detectCardType(cardNumber: string): string {
    // Remove all non-digit characters from the card number
    const cleanNumber = cardNumber.replace(/\D/g, '');

    if (/^4/.test(cleanNumber)) return 'visa'; // Visa
    if (/^(5[1-5]|2[2-7])/.test(cleanNumber)) return 'mastercard'; // Mastercard
    if (/^3[47]/.test(cleanNumber)) return 'american-express'; // American Express
    if (/^3(0[0-5]|[68])/.test(cleanNumber)) return 'diners'; // Diners Club
    if (/^6(?:011|5)/.test(cleanNumber)) return 'discover'; // Discover
    return ''; // If no match is found
  }

  onCountryChange(event: Event) {
    const countryCode = Number((event.target as HTMLSelectElement).value);
    this.getStates(countryCode);
  }

  onClickRemoveCard() {
    this.showRemoveCardPopUp = true;
  }

  goBack(): void {
    this.showAddressForm = false;
  }

  async getCountries() {
    const res = await this.countriesService.getCountrySelection<ICountries[]>();

    if (res.isError) {
      console.error('ERROR: ', res.errorMessage);
      return;
    }

    if (res.isSuccessful) {
      this.countries = res.result;

      if (this.countries.length > 0) {
        if (this.isEditing && this.editingCard) {
          const addressCard = this.editingCard?.address;
          this.selectedCountryId = addressCard.countryID
          this.addressCardForm.get('country')?.setValue(this.selectedCountryId);
        } else {
          this.selectedCountryId = this.currentLang === 'he' ? '101' : '218';
          this.addressCardForm.get('country')?.setValue(this.selectedCountryId);
        }
          
        this.getStates(Number(this.selectedCountryId));
      }
    }
  }

  async getStates(countryId: number) {
    const res = await this.statesService.getStates<IState[]>(countryId);

    if (res.isSuccessful) {
      this.states = res.result;
    }
  }

  async getPaymentMethod() {
    try {
      const res =
        await this.paymentMethodsService.getCreditCardById<ICreditCardInfo>(
          this.editingCardId
        );

      if (res.isSuccessful && res.result) {
        this.editingCard = res.result;
        this.cardType = this.editingCard?.creditCardType;
        const addressCard = this.editingCard?.address

        this.creditCardForm.get('cardNumber')?.clearValidators();
        this.creditCardForm.get('cardNumber')?.updateValueAndValidity();
        this.creditCardForm.get('cardCVV')?.clearValidators();
        this.creditCardForm.get('cardCVV')?.updateValueAndValidity();

        const formattedExpirationMonth =
          this.editingCard.expirationMonth.length === 1
            ? '0' + this.editingCard.expirationMonth
            : this.editingCard.expirationMonth;

        this.creditCardForm.patchValue({
          cardHolder: this.editingCard.cardHolder,
          cardNumber: '•••• •••• •••• ' + this.editingCard.lastFourNumbers,
          cardExpirationMM: formattedExpirationMonth,
          cardExpirationYY: this.editingCard.expirationYear,
          isDefaultCard: this.editingCard.isPrimary,
        });

        this.addressCardForm.patchValue({
          address: addressCard.address,
          city: addressCard.city,
          zipCode: addressCard.zipCode,
          country: addressCard.countryID,
          state: addressCard.state,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async editPaymentMethod() {
    const jsonData = {
      creditCardId: this.editingCard.creditCardId,
      // creditCardNumber: this.creditCardForm.get('cardNumber').value,
      cardHolder: this.creditCardForm.get('cardHolder').value,
      expirationMonth: this.creditCardForm.get('cardExpirationMM').value,
      expirationYear: this.creditCardForm.get('cardExpirationYY').value,
      // securityCode: this.creditCardForm.get('cardCVV').value,
      isPrimary: this.creditCardForm.get('isDefaultCard').value,
      address: {
        // addressID: this.editingCard.address[0].addressID,
        countryID: this.addressCardForm.get('country').value,
        city: this.addressCardForm.get('city').value,
        // state: this.addressCardForm.get('state').value,
        zipCode: this.addressCardForm.get('zipCode').value,
        address: this.addressCardForm.get('address').value,
        stateID: this.addressCardForm.get('state').value,
      },
    };

    console.log(jsonData);

    try {
      const res = await this.paymentMethodsService.editSavedCreditCard(
        jsonData
      );
      console.log(res);
      if (res.isSuccessful) {
        this.paymentUpdated.emit();
        this.closeCCModal();
      }
    } catch (error) {
      console.log(error)
    }
  }

  async addPaymentMethod() {
    const jsonData = {
      // creditCardId: 0,
      creditCardNumber: this.creditCardForm.get('cardNumber').value,
      cardHolder: this.creditCardForm.get('cardHolder').value,
      expirationMonth: this.creditCardForm.get('cardExpirationMM').value,
      expirationYear: this.creditCardForm.get('cardExpirationYY').value,
      securityCode: this.creditCardForm.get('cardCVV').value,
      isPrimary: this.creditCardForm.get('isDefaultCard').value,
      address: {
        // addressID: 0,
        countryID: this.addressCardForm.get('country').value,
        city: this.addressCardForm.get('city').value,
        state: this.addressCardForm.get('state').value,
        zipCode: this.addressCardForm.get('zipCode').value,
        address: this.addressCardForm.get('address').value,
        stateID: this.addressCardForm.get('state').value,
      },
    };

    try {
      const res = await this.paymentMethodsService.createCreditCard(jsonData);
      console.log(res);
      if (res.isSuccessful) {
        this.paymentUpdated.emit();
        this.closeCCModal();
      }
    } catch (error) {}
  }

  async removePaymentMethod(cardId: number) {
    try {
      const res = await this.paymentMethodsService.deleteCreditCardById(cardId);

      if (res.isSuccessful && res.result) {
        this.paymentUpdated.emit();
        this.closeCCModal();
      }
    } catch (error) {}
  }
}
