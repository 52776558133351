import { Injectable } from '@angular/core';
import { LocalStorageList } from '../../helpers/enums/enums.keys';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor() {}

  async setData(type: LocalStorageList | string, data: any, isJson: boolean = false) {
    await localStorage.setItem(type, isJson ? JSON.stringify(data) : data);
  }

  getData(type: LocalStorageList | string, isJson: boolean = false): any {
    try {
      const data = localStorage.getItem(type);
      return isJson ? data !== undefined && data != null ? JSON.parse(data) : undefined : data;
    } catch (error) {
      return null;
    }
  }

  hasKey(key: LocalStorageList | string): boolean {
    return localStorage.getItem(key) !== null;
  }
  
  removeData(type: LocalStorageList) {
    localStorage.removeItem(type);
  }
}