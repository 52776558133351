import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthService } from '../../../../services/auth/auth.service';
import { RouterModule } from '@angular/router';
import { InfoPopUpComponent } from '../../pop-up/info-pop-up/info-pop-up.component';
import { SpinnerComponent } from '../../loading/spinner/spinner.component';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [InfoPopUpComponent, ReactiveFormsModule, RouterModule,TranslocoModule, SpinnerComponent],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent {
  loginForm = this.formBuilder.group({
    // username: ['', [Validators.required, Validators.email]],
    username: ['', [Validators.required]],
    password: ['', Validators.required]
  });

  formSubmitted: boolean = false;
  showPopUp: boolean = false;
  isLoading: boolean = false;
  popUpMessage: string = "";

  constructor(private formBuilder: FormBuilder, private authService: AuthService) {}

  onClosePopUp() {
    this.showPopUp = false;
  }
  

  async onSubmit() {
    this.formSubmitted = true
    this.isLoading = true
    console.log(this.loginForm)
    try {
      if (this.loginForm.valid) {
        const res = await this.authService.login(this.loginForm.get('username').value, this.loginForm.get('password').value)
        
        if (res && this.authService.IsLogin()) {
          this.formSubmitted = false
          document.location.href = "/profile";
        } else {
          console.log(res);
        }
      }
    } catch (error) {
      this.isLoading = false
      console.log(error);
      
      if (error.status === 401) {
        this.showPopUp = true;
        this.popUpMessage = error.error.messageCode.toLowerCase()
      }
    } finally {
      this.isLoading = false
    }
  }
}
