import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ICreditCardInfo } from '@app/services/models/credit-card-info.model';
import { PaymentMethodsService } from '@app/services/payment-methods/payment-methods.service';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-credit-card',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './credit-card.component.html',
  styleUrl: './credit-card.component.scss'
})
export class CreditCardComponent {
  paymentMethodsService = inject(PaymentMethodsService);

  savedCards: ICreditCardInfo[] = [];
  selectedCard: ICreditCardInfo | null = null;
  selectedCardIndex: number = 0;

  @Output() selectedPaymentId = new EventEmitter<number>()

  constructor() {}

  ngOnInit(): void {
    this.getAllSavedCards();
  }

  selectCard(index: number) {
    this.selectedCardIndex = index; // Updates the index of the selected card
    this.getPaymentMethod(index)
    this.selectedPaymentId.emit(index)
  }

  isCardSelected(index: number): boolean {
    return this.selectedCardIndex === index;
  }

  async getAllSavedCards() {
    try {
      const res = await this.paymentMethodsService.getAllSavedCreditCards<ICreditCardInfo[]>()

      if (res.isSuccessful && res.result) {
        this.savedCards = res.result
      }
    } catch (error) {
      console.log(error)
    }
  }

  async getPaymentMethod(cardId: number) {
    try {
      const res = await this.paymentMethodsService.getCreditCardById<ICreditCardInfo>(cardId);

      if (res.isSuccessful && res.result) {
        this.selectedCard = res.result
      }
    } catch (error) {
      console.log(error);
    }
  }
}
