<div (click)="dropdownVisible = !dropdownVisible"
  class="flex items-center space-x-2 relative ltr:pl-2 ltr:sm:pl-4 ltr:border-l rtl:pr-2 rtl:sm:pr-4 rtl:border-r border-greyLight cursor-pointer">
  <button class="text-white">
    <span class="w-8 sm:w-10 h-8 sm:h-10 rounded-full cursor-pointer flex items-center justify-center border"
    [style.background-color]="userData.name | randomColor">
    @if (userData.initials !== '') {
      {{ userData.initials }}
    } @else {
      <img src="assets/images/icons/contact_2vnumber.svg" alt="user icon" class="w-4 sm:w-5">
    }
    </span>
  </button>

  <div class="group relative">
    <div class="hidden mlg:block group relative">
      <span class="text-black font-medium text-sm">{{ userData?.name}}</span>
      <p class="text-grey text-[10px]">{{ userData.email }}</p>
    </div>

    @if (dropdownVisible) {
    <ul class="flex flex-col text-sm font-semibold absolute ltr:right-0 rtl:left-0 mt-5 bg-white p-2 rounded-b-xl border shadow w-max">
      <!-- MY VNUMBERS -->
      <li class="border-b border-greyLight hover:bg-greyLight rounded-xl">
        <a routerLink="/profile/my-vnumbers" class="flex items-center gap-3 px-8 py-4" (click)="$event.stopPropagation();">
          <img src="assets/images/icons/profile/sim.svg" alt="sim icon" class="">
          <span transloco="profile-vn.sidebar.my-vnumbers"></span>
        </a>
      </li>
      <!-- ACCOUNT INFORMATION -->
      <li class="border-b border-greyLight hover:bg-greyLight rounded-xl">
        <a routerLink="/profile/account-information" class="flex items-center gap-3 px-8 py-4" (click)="$event.stopPropagation();">
          <img src="assets/images/icons/profile/user.svg" alt="user icon" class="">
          <span transloco="profile-vn.sidebar.account-info"></span>
        </a>
      </li>
      <!-- BILLING -->
      <li class="border-b border-greyLight hover:bg-greyLight rounded-xl">
        <a routerLink="profile/billing" class="flex items-center gap-3 px-8 py-4" (click)="$event.stopPropagation();">
          <img src="assets/images/icons/profile/card.svg" alt="card icon" class="">
          <span transloco="profile-vn.sidebar.billing"></span>
        </a>
      </li>
      <!-- SUPPORT -->
      <li class="border-b border-greyLight hover:bg-greyLight rounded-xl">
        <a routerLink="profile/support" class="flex items-center gap-3 px-8 py-4" (click)="$event.stopPropagation();">
          <img src="assets/images/icons/profile/help.svg" alt="help icon" class="">
          <span transloco="profile-vn.sidebar.support"></span>
        </a>
      </li>
      <!-- FREE CREDIT -->
      <li class="border-b border-greyLight hover:bg-greyLight rounded-xl">
        <a routerLink="/profile/free-credit" class="flex items-center gap-3 px-8 py-4" (click)="$event.stopPropagation();">
          <img src="assets/images/icons/profile/dollar.svg" alt="notification icon" class="" />
          <span transloco="profile-vn.sidebar.free-credit"></span>
        </a>
      </li>
      <!-- LOG OUT -->
      <li class="p-2 mx-auto">
        <button (click)="logout()" class="" transloco="btn-vn.log-out"></button>
      </li>
    </ul>
    }
  </div>
</div>