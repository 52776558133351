import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import intlTelInput from 'intl-tel-input';
import { SmsValidationService } from '../../services/sms-validation/sms-validation.service';
import { BtnPrimaryComponent } from '../../core/shared/buttons/btn-primary/btn-primary.component';

import { SubmitOrderService } from 'src/app/services/submit-order/submit-order.service';
import { InquiryTicketService } from '../../services/InquiryTicket/inquiry-ticket.service';
import { InfoPopUpComponent } from 'src/app/core/shared/pop-up/info-pop-up/info-pop-up.component';
import { SpinnerSmallComponent } from 'src/app/core/shared/loading/spinner-small/spinner-small.component';
import { BtnLoginComponent } from 'src/app/core/shared/buttons/btn-login/btn-login.component';

@Component({
  selector: 'app-personal-info-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BtnPrimaryComponent,
    InfoPopUpComponent,
    SpinnerSmallComponent,
    TranslocoModule,
    BtnLoginComponent
  ],
  templateUrl: './personal-info-page.component.html',
  styleUrls: ['./personal-info-page.component.scss'],
})
export class PersonalInfoPageComponent {
  submitOrderService = inject(SubmitOrderService);
  smsValidationService = inject(SmsValidationService);
  inquiryTicketService = inject(InquiryTicketService);

  personalInfoForm: FormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.required, this.phoneNumberValidator],
    dialCode: [''],
    password: ['', [Validators.required, Validators.minLength(8), this.passwordValidator]],
  });

  packageID: number = this.route.snapshot.params['packageID'];
  packageCountryName: string = this.route.snapshot.params['packageCountry'];
  didId: string = this.route.snapshot.params['didId'];

  isLoading: boolean = false;
  inquiryTicket: string;
  confirmationToken: string;
  selectedCountryName: string = '';
  customerExist: boolean = false;
  showConfirmation = false;
  formSubmitted: boolean = false;
  showPopUp: boolean = false;
  resendTimer = 0;
  isChecked: boolean = false;
  currentLang: string = '';
  allInputsComplete: boolean = false;
  translateService = inject(TranslocoService);

  @ViewChild('phoneInput') phoneInput!: ElementRef;
  @ViewChildren('confirmationInput') confirmationInputs!: QueryList<ElementRef>;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.getActiveLang();
  }

  onInputChange(event: any, index: number): void {
    const input = event.target;
    if (input.value.length > 1) {
      input.value = input.value.charAt(0);
    }
    const inputsArray = this.confirmationInputs.toArray();
    if (inputsArray[index + 1] && input.value) {
      inputsArray[index + 1].nativeElement.focus();
    }

    this.allInputsComplete = this.confirmationInputs
      .toArray()
      .every((input) => input.nativeElement.value.length === 1);
  }

  async onSubmit(type: number) {
    console.log(this.personalInfoForm);
    this.formSubmitted = true;
    const fullPhone =
      this.personalInfoForm.get('dialCode').value +
      this.personalInfoForm.get('phone').value;

    if (this.personalInfoForm.valid && this.isChecked) {
      this.isLoading = true;

      const jsonData = {
        email: this.personalInfoForm.get('email').value,
        phone: fullPhone,
        languageiso: this.currentLang,
        sendPhoneVerification: true,
      };

      try {
        const res = await this.smsValidationService.findCustomer(jsonData);

        this.confirmationToken = res.response_message;
        this.submitOrderService.setOrderData(this.personalInfoForm.value);
        if (res.value === 1 || res.value === 0) {
          type === 1 ? this.createInquiryTicket(1) : null;
          this.formSubmitted = false;
          this.showConfirmation = true;
          this.isLoading = false;
          this.startResendTimer();
        }

        if (res.value === 2) {
          type === 1 ? this.createInquiryTicket(2) : null;
          this.customerExist = true;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  async onConfirmationSubmit() {
    const code = this.confirmationInputs
      .map((input) => input.nativeElement.value)
      .join('');

    const jsonData = {
      token: this.confirmationToken,
      code: code,
    };

    try {
      const res = await this.smsValidationService.checkSMSCode(jsonData);

      if (res.response_code === 200) {
        this.router.navigate(['payment', this.packageCountryName, this.packageID, this.didId]);
      } else {
        this.openPopUp();
      }
    } catch (error) {
      console.log(error);
    }
  }

  resendSMS() {
    // Resend SMS logic
    this.startResendTimer();
    this.onSubmit(2);
  }

  async createInquiryTicket(type) {
    let marketId;

    switch (this.currentLang) {
      case 'en':
      case 'he':
        marketId = 1; // English/Hebrew market
        break;
      case 'es':
        marketId = 2; // Spanish market
        break;
      case 'pt':
        marketId = 3; // Portuguese market
        break;
      default:
        marketId = 1; // default to English/Hebrew market if language is not detected
        break;
    }

    const fullName =
      this.personalInfoForm.get('firstName').value +
      ' ' +
      this.personalInfoForm.get('lastName').value;

    var jsonData = {
      name: fullName,
      inquirySourceId: '40', // inquirySourceId 16 = WebSite
      inquiryCategoryId: '37', // inquiryCategoryIdID 37 = Online Sale
      inquirySubCategoryId: '147', // inquirySubCategoryId 147 = Sale not submited
      email: this.personalInfoForm.get('email').value,
      languageISO: this.currentLang,
      affiliate: 'D_011Site_Plans_' + this.selectedCountryName,
      CountryCode: this.personalInfoForm.get('dialCode').value,
      PhoneNumber: this.personalInfoForm.get('phone').value,
      MarketID: marketId,
      inquiryTypeId: type,
      properties: {
        PackageID: this.packageID,
      },
    };

    try {
      const res = await this.inquiryTicketService.createInquiryTicket(jsonData);

      if (res.response_code === 200) {
        this.inquiryTicket = res.inquiryTicket;
        const currentOrderData = this.submitOrderService.getOrderData();
        currentOrderData.inquiryTicket = this.inquiryTicket;
        this.submitOrderService.setOrderData(currentOrderData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  checkPermissions(value) {
    this.isChecked = value;
  }

  ngAfterViewInit() {
    const inputElement = this.phoneInput.nativeElement;
    if (inputElement) {
      const iti = intlTelInput(inputElement, {
        initialCountry: 'US',
        separateDialCode: true,
        formatOnDisplay: true,
        strictMode: true,
        utilsScript:
          'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js',
      });

      inputElement.addEventListener('input', () => {
        const phoneNumber = iti.getNumber();
        const dialCode = iti.getSelectedCountryData().dialCode;
        const nationalNumber = phoneNumber.replace(`+${dialCode}`, '').trim();

        this.selectedCountryName = iti.getSelectedCountryData().name;
        this.personalInfoForm.get('phone').setValue(nationalNumber);
        this.personalInfoForm.get('dialCode').setValue(dialCode);
        this.personalInfoForm.get('phone').updateValueAndValidity();
      });
    }
  }

  phoneNumberValidator(
    control: FormControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve) => {
      const cleanedNumber = control.value;

      if (cleanedNumber) {
        const input = document.querySelector('#phoneInput') as HTMLInputElement;
        const iti = intlTelInput.getInstance(input);

        if (iti.isValidNumber()) {
          resolve(null);
        } else {
          resolve({ invalidPhoneNumber: true });
        }
      } else {
        resolve(null);
      }
    });
  }

  startResendTimer() {
    this.resendTimer = 60;
    const timer = setInterval(() => {
      this.resendTimer--;
      if (this.resendTimer <= 0) {
        clearInterval(timer);
      }
    }, 1000);
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    if (!/[A-Z]/.test(value)) {
      return { noUpperCase: true };
    }

    if (!/[A-Za-z]/.test(value)) {
      return { noLetter: true };
    }

    if (!/[0-9]/.test(value)) {
      return { noNumber: true };
    }
    return null;
  }

  openPopUp() {
    this.showPopUp = true;
  }

  onClosePopUp() {
    this.showPopUp = false;
  }
}
