import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnPrimaryComponent } from 'src/app/core/shared/buttons/btn-primary/btn-primary.component';
import { SearchCountriesComponent } from 'src/app/core/shared/search-countries/search-countries.component';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-get-your-number-page',
  standalone: true,
  imports: [
    CommonModule,
    SearchCountriesComponent,
    BtnPrimaryComponent,
    TranslocoModule
  ],
  templateUrl: './get-your-number-page.component.html',
  styleUrls: ['./get-your-number-page.component.scss'],
})
export class GetYourNumberPageComponent {}
