import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { InfoPopUpComponent } from '@app/core/shared/pop-up/info-pop-up/info-pop-up.component';
import { AuthService } from '@app/services/auth/auth.service';
import { ApiMessageCode } from '@app/services/models/base/ApiMessageCode';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-password-forgot-page',
  standalone: true,
  imports: [InfoPopUpComponent, ReactiveFormsModule, TranslocoModule],
  templateUrl: './password-forgot-page.component.html',
  styleUrl: './password-forgot-page.component.scss'
})
export class PasswordForgotPageComponent {

  translocoService = inject(TranslocoService);
  authService = inject(AuthService);

  formSubmitted: boolean = false;
  showPopUp: boolean = false;

  popUpMessage: string = "";
  currentLang: string = "";

  emailForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  })

  constructor(private fb: FormBuilder) {
    this.currentLang = this.translocoService.getActiveLang()
  }

  async onSubmit() {
    this.formSubmitted = true;

    if (this.emailForm.valid) {
      this.formSubmitted = false;
      
      const jsonData = {
        email: this.emailForm.get('email').value,
        languageIso: this.currentLang
      }

      try {
        const res = await this.authService.resetPassword(jsonData);
        
        if (res.isSuccessful && res.result) {
          this.showPopUp = true;
          this.popUpMessage = 'verifying-password'
        }
      } catch (error) {
        console.log(error)
        const apiMessageCode = ApiMessageCode.MessageCode
        const message = error.error.messages[0]
        if (message.code === apiMessageCode.emailNotExist) {
          this.showPopUp = true;
          this.popUpMessage = 'email-not-exist';
        }
      }
    }
  }
}
