<div class="mb-6">
  <h3 class="text-base font-semibold py-8" [transloco]="'profile-vn.my-vnumbers.call-history.' + title"></h3>
  @if (title === 'recent' && calls.length === 0) {
    <div class="flex items-center justify-center text-sm font-medium border py-2 px-6 rounded-xl w-fit mx-auto" transloco="profile-vn.my-vnumbers.call-history.no-recent-calls"></div>
  }
  @for (call of calls; track $index) {
    <div class="flex flex-col sm:flex-row sm:items-center justify-between py-5 text-sm border-t border-greyLight gap-4">
      <!-- NUMBER -->
      <div class="flex items-center sm:min-w-[180px]">
        <span class="w-8 h-8 rounded-full text-white flex items-center justify-center text-sm mr-3"
          [style.background-color]="call?.callerID | randomColor">
          <img src="assets/images/icons/profile/user-2.svg" alt="user icon" class="w-4">
        </span>
        <span>{{ call?.isIncomingCall === true ? call?.callerID : call?.callDestination }}</span>
      </div>
      <!-- DATE -->
      <div class="flex items-center">
        <span class="flex">
          {{ call?.dialedTime }}
        </span>
      </div>
      <!-- DATE -->
      <div class="flex items-center">
        <span class="flex">
          @if (call?.isIncomingCall) {
          <img src="assets/images/icons/profile/income-arrow.svg" alt="arrow" class="w-4">
          <span transloco="profile-vn.my-vnumbers.call-history.income"></span>
          } @else {
          <img src="assets/images/icons/profile/outcome-arrow.svg" alt="arrow" class="w-4">
          <span transloco="profile-vn.my-vnumbers.call-history.outcome"></span>
          }
        </span>
      </div>
      <!-- AMOUNT -->
      <div class="flex items-center">
        <span class="">Intl. ${{ call?.cost }}</span>
      </div>
    </div>
  }
</div>